import { Stack } from "@mui/material"
import Box from "@mui/material/Box"
import { PropsWithChildren } from "react"

// Spacer to account for navigation, so content doesn't get stuck underneath
export const BottomSpacer = () => (
  <Box
    aria-hidden
    paddingBottom={({ constants, spacing }) =>
      `calc(max(env(safe-area-inset-bottom), ${spacing(3)}) + ${
        constants.buttonHeight
      }px)`
    }
  />
)

export const BottomBar = ({ children }: PropsWithChildren) => (
  <Stack
    gap={2}
    alignItems={"center"}
    direction={"row"}
    sx={{
      position: "fixed",
      backgroundColor: ({ palette }) => `${palette.background.primary}`,
      // https://developer.mozilla.org/en-US/docs/Web/CSS/env
      // safe-area-inset-bottom is the height of the Home Indicator on iOS
      // and related features on Android
      bottom: ({ spacing }) =>
        `max(env(safe-area-inset-bottom), ${spacing(3)})`,
      left: ({ spacing }) => spacing(3),
      right: ({ spacing }) => spacing(3),
    }}
  >
    {children}
  </Stack>
)
