import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import englishTranslations from "./locales/en.json"

export const languages = ["en"]

void i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: import.meta.env.MODE !== "test" && !import.meta.env.PROD,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: { translation: englishTranslations },
    },
  })

export default i18n
