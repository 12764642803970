import { Box, IconButton } from "@mui/material"
import { MouseEventHandler } from "react"

import { HEADER_LOCATORS } from "@/desktop/components/Header/locators"
import { useIsDesktop } from "@/shared/utils"

import { MoonIcon, SunIcon } from "../icons/icons"
import { ThemeMode } from "./ThemeContext"
import { useTheme } from "./useTheme"

export const ThemeToggle = () => {
  const { themeMode, toggleTheme } = useTheme()
  const isDesktop = useIsDesktop()
  const isDarkTheme = themeMode === ThemeMode.DARK

  const handleClick: MouseEventHandler = (evt) => {
    toggleTheme()
    evt.stopPropagation()
  }

  return (
    <IconButton
      onClick={handleClick}
      color="inherit"
      data-testid={HEADER_LOCATORS.themeToggle}
      sx={{
        borderRadius: 14,
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          ".toggleSwitch": {
            opacity: 1,
          },
          svg: {
            transform: isDarkTheme && isDesktop ? "translateX(-20px)" : "",
            path: {
              fill: isDarkTheme ? "" : "inherit",
            },
          },
        },
        svg: {
          transition: "all 0.3s ease-in-out",
        },
      }}
    >
      <Box display="flex" alignItems="center" gap={1.5}>
        <Box
          className="toggleSwitch"
          sx={{
            display: isDesktop ? "block" : "none",
            opacity: 0,
            width: 14,
            height: 14,
            backgroundColor: "background.brandPrimary",
            borderRadius: "50%",
            transform: isDarkTheme ? "translateX(25px)" : "",
            transition: "all 0.3s ease-in-out",
          }}
        />
        {isDarkTheme ? <MoonIcon /> : <SunIcon />}
      </Box>
    </IconButton>
  )
}
