import { Direction } from "@/shared/services/orders"

export const RootPath = {
  index: "/mobile",
  trade: "/mobile/trade",
  tradeInstrument,
  tradeInstrumentTrade,
  tradeInstrumentTradeReceipt,
  orderHistory: "/mobile/orderhistory",
  positions: "/mobile/positions",
}

function tradeInstrument(symbol: string) {
  return `${RootPath.trade}/instrument/${symbol}`
}
tradeInstrument.routePattern = `${RootPath.trade}/instrument/:symbol`

function tradeInstrumentTrade(symbol: string, direction: Direction) {
  return `${RootPath.trade}/instrument/${symbol}/${direction}`
}
tradeInstrumentTrade.routePattern = `${RootPath.trade}/instrument/:symbol/:direction`

function tradeInstrumentTradeReceipt(symbol: string, direction: Direction) {
  return `${RootPath.trade}/instrument/${symbol}/${direction}/receipt`
}
tradeInstrumentTradeReceipt.routePattern = `${RootPath.trade}/instrument/:symbol/:direction/receipt`
