import {
  registerWSService$,
  WSEndPoint,
  WSService,
} from "../utils/registerWSService$"

export interface AccountSummary {
  account_number: string
  buying_power: string
  cash: string
  portfolio_value: string
}

export interface AccountSummaryServiceSchema {
  getAccountSummary: WSService<WSEndPoint.getAccountSummary>
}

export const AccountSummaryService: AccountSummaryServiceSchema = {
  getAccountSummary: registerWSService$(WSEndPoint.getAccountSummary),
}
