import { Box, Skeleton, Stack, Typography } from "@mui/material"
import Collapse from "@mui/material/Collapse"
import { Subscribe } from "@react-rxjs/core"
import { PropsWithChildren, useState } from "react"
import { useTranslation } from "react-i18next"

import { LogoShort, ThemeToggle } from "@/shared/components"
import { useAccountSummary } from "@/shared/services/accountSummary"
import {
  ProfitAndLossType,
  useProfitAndLoss,
} from "@/shared/services/positions"
import {
  formatCurrency,
  formatPercentageSigned,
  formatProfitAndLoss,
  getChangeTextColor,
} from "@/shared/utils"

import { AppHeaderLocators } from "./locators"

const AccountNumber = () => {
  return useAccountSummary().account_number
}

const PortfolioBalance = () => (
  <Typography
    variant="Text md/Medium"
    data-testid={AppHeaderLocators.portfolioBalance}
  >
    {formatCurrency(useAccountSummary().portfolio_value)}
  </Typography>
)

const CashBalance = () => (
  <Typography
    variant="Text md/Medium"
    data-testid={AppHeaderLocators.cashBalance}
  >
    {formatCurrency(useAccountSummary().cash)}
  </Typography>
)

interface ProfitAndLossProps {
  type: ProfitAndLossType
  "data-testid": string
}

const ProfitAndLoss = ({ type, "data-testid": testId }: ProfitAndLossProps) => {
  const { pnl, pnlPercent } = useProfitAndLoss(type)
  return (
    <Typography
      variant="Text md/Regular"
      color={getChangeTextColor({ change: pnl })}
      data-testid={testId}
    >
      {formatProfitAndLoss(pnl)} ({formatPercentageSigned(pnlPercent)})
    </Typography>
  )
}

interface ExpandedHeaderInfoFieldProps {
  valueName: string
}

const ExpandedHeaderInfoField = ({
  children,
  valueName,
}: PropsWithChildren<ExpandedHeaderInfoFieldProps>) => {
  const { t } = useTranslation()
  return (
    <Stack flexBasis="50%" gap={0.5}>
      <Typography variant="Text lg/Semibold" component="h2">
        {t(valueName)}
      </Typography>

      <Subscribe fallback={<Skeleton />}>{children}</Subscribe>
    </Stack>
  )
}

export const AppHeader = () => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Stack paddingX={6} paddingTop={6} gap={3} color="text.primary">
      <Stack direction="row">
        <Stack
          direction="row"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          gap={2}
          flexGrow={1}
          data-testid={AppHeaderLocators.main}
          onClick={() => setExpanded(!expanded)}
        >
          <LogoShort />
          <Box>
            <Typography
              variant="Text xl/Semibold"
              component="h1"
              sx={{ lineHeight: "normal" }}
              data-testid={AppHeaderLocators.accountName}
            >
              Adaptive
            </Typography>

            <Typography
              variant="Text lg/Semibold"
              sx={{ lineHeight: "normal" }}
              data-testid={AppHeaderLocators.accountInfo}
            >
              <Subscribe fallback={<Skeleton sx={{ width: "100%" }} />}>
                {expanded ? <AccountNumber /> : <PortfolioBalance />}
              </Subscribe>
            </Typography>
          </Box>
        </Stack>
        <ThemeToggle />
      </Stack>

      <Collapse in={expanded} data-testid={AppHeaderLocators.expanded}>
        <Stack gap={2}>
          <Stack direction="row" gap={3}>
            <ExpandedHeaderInfoField valueName="portfolioValue">
              <PortfolioBalance />
            </ExpandedHeaderInfoField>
            <ExpandedHeaderInfoField valueName="cashValue">
              <CashBalance />
            </ExpandedHeaderInfoField>
          </Stack>
          <Stack direction="row" gap={3}>
            <ExpandedHeaderInfoField valueName="totalGainLoss">
              <ProfitAndLoss
                type="total"
                data-testid={AppHeaderLocators.totalGainLoss}
              />
            </ExpandedHeaderInfoField>
            <ExpandedHeaderInfoField valueName="dailyGainLoss">
              <ProfitAndLoss
                type="daily"
                data-testid={AppHeaderLocators.dailyGainLoss}
              />
            </ExpandedHeaderInfoField>
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  )
}
