import { Skeleton, styled } from "@mui/material"
import { useState } from "react"

import { featuredInstrumentSymbols } from "@/shared/services/instruments/instruments"

interface Props {
  symbol: string
  variant?: LogoVariant
  "data-testid": string
}

export type LogoVariant = "sm" | "md" | "lg"

const logoSize: Record<LogoVariant, number> = {
  sm: 24,
  md: 36,
  lg: 44,
}

export const InstrumentLogo = ({
  symbol,
  variant = "lg",
  "data-testid": testId,
}: Props) => {
  const [showLogo, setShowLogo] = useState(false)

  return (
    <Container size={logoSize[variant]}>
      <img
        src={getLogo(symbol)}
        style={{ display: showLogo ? undefined : "none" }}
        onLoad={() => setShowLogo(true)}
        data-testid={`${testId}.image`}
      />

      {!showLogo && (
        <Skeleton
          variant="circular"
          data-testid={`${testId}.placeholder`}
          width={`${logoSize[variant]}px`}
          height={`${logoSize[variant]}px`}
        />
      )}
    </Container>
  )
}

const Container = styled("div")<{ size: number }>`
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 ${({ size }) => `${size}px`};

  img,
  .MuiSkeleton-root {
    width: 100%;
    height: 100%;
  }
`

const getLogo = (symbol: string) => {
  if (!import.meta.env.VITE_MOCK_LOGOS && !import.meta.env.VITE_MOCKS) {
    return `/api/logos/${symbol}`
  }

  if (featuredInstrumentSymbols.includes(symbol)) {
    return `/stockIcons/${symbol}.png`
  }

  const imageSize = 56 * 2
  const logoCanvas = document.createElement("canvas")
  logoCanvas.setAttribute("width", `${imageSize}px`)
  logoCanvas.setAttribute("height", `${imageSize}px`)
  const ctx = logoCanvas.getContext("2d")!

  // background
  ctx.fillStyle = "#333333"
  ctx.fillRect(0, 0, imageSize, imageSize)

  // font size/style
  ctx.font = "bold 30px sans-serif"
  ctx.textAlign = "center"
  ctx.textBaseline = "middle"
  ctx.fillStyle = "white"

  // draw text
  ctx.fillText(symbol, imageSize / 2, imageSize / 2)

  return logoCanvas.toDataURL("image/png")
}
