import { bind } from "@react-rxjs/core"
import { map, startWith } from "rxjs"

import { InstrumentsService } from "@/shared/services/TradingGateway"

export type Quote =
  | {
      bidPrice: number
      askPrice: number
      timestamp: string
      marketOpen: true
    }
  | {
      bidPrice?: never
      askPrice?: never
      timestamp: string
      marketOpen: false
    }

export const [useLatestQuote, latestQuote$] = bind<[string], Quote>(
  (symbol: string) =>
    InstrumentsService.getLatestQuote({ symbol }).pipe(
      map(({ bp, ap, t }) => {
        const marketOpen = ap !== 0 || bp !== 0
        if (marketOpen) {
          return {
            bidPrice: bp,
            askPrice: ap,
            timestamp: t,
            marketOpen,
          }
        } else {
          return {
            timestamp: t,
            marketOpen,
          }
        }
      }),
      // TODO temporary until the server sends us the state of market and we figure out how to handle
      // what to display in the components when there are no emissions
      startWith<Quote>({
        timestamp: new Date().toISOString(),
        marketOpen: false,
      }),
    ),
)
