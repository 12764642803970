import { useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { useIsDesktop } from "./shared/utils/useIsDesktop"
import { useIsLoginPage } from "./shared/utils/useIsLoginPage"

export const DesktopMobileSwitch = () => {
  const isDesktop = useIsDesktop()
  const isLoginPage = useIsLoginPage()
  const urlPath = useLocation().pathname
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoginPage) {
      navigate(`/${isDesktop ? "desktop" : "mobile"}/login`)
    } else if (
      !isDesktop &&
      (urlPath.includes("/desktop/") || urlPath === "/")
    ) {
      navigate("/mobile/")
    } else if (isDesktop && (urlPath.includes("/mobile/") || urlPath === "/")) {
      navigate("/desktop/")
    }
  }, [urlPath, isDesktop, isLoginPage, navigate])

  return <Outlet />
}
