import EventBus from "@vertx/eventbus-bridge-client.js"
import { ReplaySubject } from "rxjs"

import {
  ConnectionState,
  setEmailLogged,
  setEventBusConnectionState,
} from "./login/signal"

type ServiceEndpoints = Record<string, { streaming: string; cmd: string }>

export const eventBus = new EventBus("/eventbus")
eventBus.enableReconnect(true)
eventBus.onopen = () => {
  setEventBusConnectionState(ConnectionState.CONNECTED)
}

// Will be called in the event of a "cold start" of the deployment server,
// corresponding with logs like <base>/eventbus/info?t=1738763763881 500 (Internal Server Error),
// repeatedly, with the eventbus impl set to reconnect
eventBus.onclose = () => {
  setEventBusConnectionState(ConnectionState.DISCONNECTED)
  setEmailLogged(null)
  localStorage.removeItem("email")
}

export const serviceStreams$ = new ReplaySubject<ServiceEndpoints>(1)
