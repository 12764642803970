const BASE = "HeaderLocators"
const NAVIGATION_BASE = "navigation"

export const HEADER_LOCATORS = {
  main: `${BASE}.main`,
  accountSummary: `${BASE}.accountSummary`,
  accountName: `${BASE}.accountName`,
  accountValue: `${BASE}.accountValue`,
  logo: `${BASE}.logo`,
  themeToggle: `${BASE}.themeToggle`,
}

export const NAVIGATION_LOCATORS = {
  trade: `${NAVIGATION_BASE}.trade`,
  myPositions: `${NAVIGATION_BASE}.myPositions`,
  orderHistory: `${NAVIGATION_BASE}.orderHistory`,
}
