import { Skeleton } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useTranslation } from "react-i18next"

import { setQty, useQtyValue } from "@/shared/services/orders"

import { OrderInput } from "../OrderInputs"

export const MarketOrderForm = () => {
  const { t } = useTranslation()

  const qtyValue = useQtyValue()

  return (
    <Subscribe fallback={<Skeleton />}>
      <OrderInput
        onChange={(value) => setQty(value)}
        data-testid="marketQty"
        value={qtyValue}
        name="marketQty"
        label={t("quantity")}
      />
    </Subscribe>
  )
}
