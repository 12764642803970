import { Box, Stack } from "@mui/material"
import { useState } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import { RootPath } from "@/desktop/paths"
import {
  FeaturedInstruments,
  InstrumentsTable,
  TRADE_PAGE_LOCATORS,
} from "@/shared/components"
import {
  setSearchValue,
  useSearchResult,
} from "@/shared/services/instruments/searchInstruments"

import { InstrumentPage } from "../InstrumentsPage"
import { OrderContainer } from "../Order/OrderContainer"
import { SearchController } from "./SearchController"

const SearchResults = () => {
  const instruments = useSearchResult()
  return (
    <InstrumentsTable
      instruments={instruments}
      data-testid={TRADE_PAGE_LOCATORS.search}
    />
  )
}

interface TradeMainProps {
  heightAdjustment: number
}

export const TradeMain = ({ heightAdjustment }: TradeMainProps) => {
  const [isSearchActive, setIsSearchActive] = useState(false)

  return (
    <Stack
      direction="row"
      spacing={1}
      height={(theme) =>
        `calc(100vh - ${theme.constants.desktopHeaderHeight}px - ${heightAdjustment}px)`
      }
    >
      <Box
        display="flex"
        minWidth={400}
        flexDirection="column"
        overflow="scroll"
        padding={2}
        borderRadius={1}
        bgcolor="background.primary"
      >
        <SearchController
          setIsSearchActive={setIsSearchActive}
          isSearchActive={isSearchActive}
          setSearchValue={setSearchValue}
          searchResultComponent={<SearchResults />}
          data-testid={TRADE_PAGE_LOCATORS.search}
        >
          <FeaturedInstruments />
        </SearchController>
      </Box>
      <Routes>
        <Route
          key="/"
          path="/"
          element={<Navigate to={RootPath.tradeInstrument("AAPL")} replace />}
        />
        <Route
          key="/instrument/:symbol"
          path="/instrument/:symbol"
          element={
            <>
              <InstrumentPage />
              <OrderContainer />
            </>
          }
        />
      </Routes>
    </Stack>
  )
}
