import { createSignal } from "@react-rxjs/utils"
import { tap, withLatestFrom } from "rxjs"

import { PAGE_SIZE } from "@/shared/utils"

import { eventBus, serviceStreams$ } from "../connection"
import { WSEndPoint } from "./registerWSService$"

const [nextPage$, getNextPage] = createSignal<{
  endPoint: WSEndPoint
  continuationToken?: string
}>()

nextPage$
  .pipe(
    withLatestFrom(serviceStreams$),
    tap(([{ endPoint, continuationToken }, serviceEndpoints]) => {
      if (!eventBus || !serviceEndpoints) {
        throw Error()
      }

      eventBus.send(serviceEndpoints[endPoint].cmd, {
        type: "page",
        size: PAGE_SIZE,
        continuationToken,
      })
    }),
  )
  .subscribe()

export { getNextPage }
