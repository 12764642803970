import { Skeleton, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"

import { TRADE_TICKET_LOCATORS } from "@/mobile/components/tradeScreen/TradeTicket/locators"
import { useIsDesktop, withSubscribe } from "@/shared/utils"

import { CashValue, PortfolioValue } from "../TradeValues"
import { DetailField } from "./DetailField"

export const BalanceDetails = withSubscribe(
  () => {
    const { t } = useTranslation()
    const isDesktop = useIsDesktop()

    return (
      <Stack paddingTop={isDesktop ? 2 : 0} paddingBottom={2} rowGap={2}>
        <DetailField label={t("availableBalance")} testId="availableBalance">
          <CashValue />
        </DetailField>
        <DetailField
          label={t("locked")}
          data-testid={TRADE_TICKET_LOCATORS.currentPosition}
          testId="locked"
        >
          <PortfolioValue />
        </DetailField>
      </Stack>
    )
  },
  { fallback: <Skeleton /> },
)
