export const RootPath = {
  index: "/desktop",
  trade: "/desktop/trade",
  tradeInstrument,
  orderHistory: "/desktop/orderhistory",
  positions: "/desktop/positions",
}

function tradeInstrument(symbol: string) {
  return `${RootPath.trade}/instrument/${symbol}`
}
tradeInstrument.routePattern = `${RootPath.trade}/instrument/:symbol`
