import { bind } from "@react-rxjs/core"

import {
  emailLogged$,
  eventBusConnectionState$,
  isErrorInLogin$,
  isEventBusTimeout$,
} from "./signal"

export const [useEmailLogged] = bind(emailLogged$)
export const [useIsErrorInLogin] = bind(isErrorInLogin$)
export const [useEventBusConnectionState] = bind(eventBusConnectionState$)
export const [useIsEventBusTimeout] = bind(isEventBusTimeout$)
