import { Box, Typography } from "@mui/material"

export const Loading = () => {
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography>Loading...</Typography>
    </Box>
  )
}
