import { Snackbar } from "@mui/material"
import { Theme } from "@mui/material/styles"

import { AUTO_HIDE_DURATION } from "../utils/constants"
import { SnackbarMessageType } from "./SnackbarMessageType"

const getBackgroundColor = (theme: Theme, type: SnackbarMessageType) => {
  switch (type) {
    case SnackbarMessageType.ERROR:
      return theme.palette.error.main
    case SnackbarMessageType.INFO:
      return theme.palette.info.main
    case SnackbarMessageType.SUCCESS:
      return theme.palette.success.main
    case SnackbarMessageType.WARNING:
      return theme.palette.warning.main
  }
}

export type SnackbarMessageProps = {
  type: SnackbarMessageType
  isOpen: boolean
  onClose: () => void
  message?: string
}

export const SnackbarMessage = ({
  type,
  isOpen,
  onClose,
  message: errorMessage,
}: SnackbarMessageProps) => {
  return (
    <Snackbar
      sx={{
        ".MuiSnackbarContent-root": {
          justifyContent: "center",
          color: "white",
          backgroundColor: (theme) => getBackgroundColor(theme, type),
        },
      }}
      autoHideDuration={AUTO_HIDE_DURATION}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={isOpen}
      onClose={onClose}
      message={errorMessage ?? ""}
    />
  )
}
