import { bind } from "@react-rxjs/core"
import { map } from "rxjs"

import { Position, PositionsService } from "../TradingGateway"
import { WSEndPoint } from "../utils/registerWSService$"
import { scanPagingEvents } from "../utils/scanPagingEvents"

export const [usePositions, positions$] = bind<Position[]>(
  PositionsService.getPositions().pipe(
    scanPagingEvents<Position>(WSEndPoint.getPositions),
  ),
)

export const [usePositionsValue] = bind<number>(
  positions$.pipe(
    map((positions) =>
      positions.reduce(
        (totalValue, position) => totalValue + Number(position.market_value),
        0,
      ),
    ),
  ),
)

export type ProfitAndLossType = "total" | "daily"

export const [useProfitAndLoss] = bind((type: ProfitAndLossType) =>
  positions$.pipe(
    map((positions) => {
      const { pnlSum, weightedPnLChangeSum, totalQuantity } = positions.reduce(
        (
          acc,
          {
            unrealized_pl,
            unrealized_plpc,
            unrealized_intraday_pl,
            unrealized_intraday_plpc,
            qty,
          },
        ) => {
          const pnl = Number(
            type === "total" ? unrealized_pl : unrealized_intraday_pl,
          )
          const pnlPercent = Number(
            type === "total" ? unrealized_plpc : unrealized_intraday_plpc,
          )

          return {
            pnlSum: acc.pnlSum + pnl,
            weightedPnLChangeSum:
              acc.weightedPnLChangeSum + pnlPercent * Number(qty),
            totalQuantity: acc.totalQuantity + Number(qty),
          }
        },
        { pnlSum: 0, weightedPnLChangeSum: 0, totalQuantity: 0 },
      )

      const pnlPercent =
        totalQuantity > 0 ? weightedPnLChangeSum / totalQuantity : 0

      return {
        pnl: pnlSum,
        pnlPercent,
      }
    }),
  ),
)
