export const Logo = () => (
  <svg
    width="125"
    height="28"
    viewBox="0 0 1500.34 337.54"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Adaptive Financial Consulting Logo</title>

    <g fill="currentColor">
      <rect x="42.1" y="55.98" width="41.82" height="174.24" />
      <rect x="103.96" y="97.8" width="41.82" height="174.24" />
      <rect x="166.68" y="69.92" width="41.82" height="174.24" />
      <rect x="229.41" y="42.04" width="41.82" height="174.24" />
      <path d="M427.82,42.27h38.64l77.55,201.86h-40.32l-16.8-45.35h-80.35l-16.8,45.35h-39.48l77.55-201.86ZM474.86,166.57l-28.28-76.71-28.28,76.71h56.55Z" />
      <path d="M540.93,171.33c0-44.23,26.32-74.75,68.87-74.75,17.92,0,33.6,7.56,42,18.76V40.03h35.28v204.1h-35.28v-17.08c-6.72,10.92-22.68,19.32-41.44,19.32-43.39,0-69.43-30.8-69.43-75.03ZM654.04,171.33c0-26.6-15.4-44.23-38.64-44.23s-38.64,17.64-38.64,44.23,15.4,44.52,38.64,44.52,38.64-17.64,38.64-44.52Z" />
      <path d="M706.11,204.37c0-25.48,18.48-41.16,50.67-45.35l29.12-3.92c7.84-1.12,11.76-5.04,11.76-11.48,0-10.64-10.36-19.6-27.44-19.6-15.96,0-28,8.4-29.12,23.24h-34.16c3.36-31.08,26.88-50.67,63.27-50.67,38.36,0,62.71,21.84,62.71,56.27v51.23c0,8.12,3.08,11.76,8.96,11.76,1.96,0,4.76-.56,4.76-.56v28.84s-8.4,1.68-16.8,1.68c-14.84,0-25.76-7-27.44-20.72-6.44,12.88-24.08,21.28-45.63,21.28-29.4,0-50.67-17.64-50.67-41.99ZM798.5,181.41v-4.2l-35.84,5.88c-14.28,2.24-21.28,8.96-21.28,19.6,0,9.8,8.4,16.8,21.28,16.8,22.68,0,35.84-16.24,35.84-38.08Z" />
      <path d="M863.74,98.82h35.28v17.08c7.84-11.48,24.08-19.32,43.12-19.32,41.44,0,68.03,30.8,68.03,74.75s-28.28,75.03-70.55,75.03c-17.08,0-32.48-6.72-40.6-17.08v67.19h-35.28V98.82ZM974.04,171.33c0-26.6-15.68-44.23-38.36-44.23s-38.92,17.64-38.92,44.23,15.68,44.52,38.92,44.52,38.36-17.64,38.36-44.52Z" />
      <path d="M1109.83,128.22h-33.88v66.35c0,13.72,8.4,21.28,20.16,21.28,5.32,0,10.92-1.12,13.72-1.96v30.52c-3.36.84-10.08,1.96-17.92,1.96-29.96,0-51.23-15.68-51.23-48.15v-69.99h-24.64v-29.4h24.64v-42.83h35.28v42.83h33.88v29.4Z" />
      <path d="M1168.06,42.03v36.96h-38.36v-36.96h38.36ZM1131.11,98.82h35.28v145.3h-35.28V98.82Z" />
      <path d="M1180.22,98.82h35.55l34.16,104.71,33.88-104.71h35.55l-52.91,145.3h-33.04l-53.19-145.3Z" />
      <path d="M1387.97,96.58c47.59,0,70.55,35.84,70.55,70.83,0,5.88-.56,12.88-.56,12.88h-106.11c3.08,23.24,17.64,36.4,38.91,36.4,16.24,0,28.56-7.28,31.92-20.16h35c-4.76,31.64-31.92,49.83-67.75,49.83-47.88,0-72.79-36.96-72.79-75.03s23.24-74.75,70.83-74.75ZM1420.45,153.69c-1.4-17.36-12.6-30.24-32.48-30.24-18.2,0-30.52,10.92-35,30.24h67.47Z" />
    </g>
  </svg>
)

export const LogoShort = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 300 300"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="currentColor">
      <rect x="42.1" y="55.98" width="41.82" height="174.24" />
      <rect x="103.96" y="97.8" width="41.82" height="174.24" />
      <rect x="166.68" y="69.92" width="41.82" height="174.24" />
      <rect x="229.41" y="42.04" width="41.82" height="174.24" />
    </g>
  </svg>
)
