import { from, Observable, switchMap } from "rxjs"

import { eventBus, serviceStreams$ } from "../connection"
import type {
  LimitOrderRequest,
  MarketOrderRequest,
  Order,
  StopOrderRequest,
} from "../TradingGateway"

export enum EventBusEndPoint {
  placeOrder = "orders",
}

type Request = {
  [EventBusEndPoint.placeOrder]:
    | MarketOrderRequest
    | LimitOrderRequest
    | StopOrderRequest
}

type Response = {
  [EventBusEndPoint.placeOrder]: Order
}

export type EventBusService<E extends EventBusEndPoint> =
  Request[E] extends undefined
    ? () => Observable<Response[E]>
    : (message: Request[E]) => Observable<Response[E]>

export function createEventBusRequest$<E extends EventBusEndPoint>(
  endPoint: E,
): EventBusService<E> {
  return (message?: Request[E]) => {
    return serviceStreams$.pipe(
      switchMap((serviceEndpoints) => {
        return from(
          new Promise<Response[E]>((resolve) => {
            eventBus.send(
              serviceEndpoints[endPoint].cmd,
              message,
              (_error: object, message: { body: Response[E] }) => {
                resolve(message.body)
              },
            )
          }),
        )
      }),
    )
  }
}
