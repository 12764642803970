import Box from "@mui/material/Box"

import { iconMap, IconNames } from "./iconMap"

interface IconProps {
  name: IconNames
  component?: "span"
  size?: "xs" | "sm" | "md" | "lg"
  color?: string
}

const sizes: Record<Required<IconProps>["size"], number> = {
  xs: 14,
  sm: 24,
  md: 33,
  lg: 40,
}

export const Icon = ({
  name,
  component,
  size: sizeClass = "md",
  color,
}: IconProps) => {
  const IconComponent = iconMap[name]
  const size = sizes[sizeClass]
  return (
    <Box
      color={color}
      component={component}
      height={size}
      width={size}
      display="flex"
    >
      <IconComponent />
    </Box>
  )
}
