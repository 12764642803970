import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import {
  CurrentPriceValue,
  PortfolioValue,
  TradeDetail,
} from "@/shared/components"
import { InstrumentTitle } from "@/shared/components/instruments/InstrumentTitle"
import { Order } from "@/shared/services/TradingGateway"
import { formatDate } from "@/shared/utils"

interface TradeReceiptInformationProps {
  symbol: string
  orderResponse: Order
}

export const TradeReceiptInformation = ({
  symbol,
  orderResponse,
}: TradeReceiptInformationProps) => {
  const { t } = useTranslation()

  if (!orderResponse) {
    throw Error()
  }

  return (
    <Stack gap={1}>
      <Typography
        paddingY={2}
        variant="Text xl/Regular"
        sx={({ palette }) => ({
          borderBottomWidth: 2,
          borderBottomStyle: "solid",
          borderColor: palette.divider,
        })}
      >
        {t("tradeInformation.tradeReceipt")}
      </Typography>
      <InstrumentTitle
        symbol={symbol}
        logoVariant="md"
        testId="tradeInformation"
      />
      <Stack gap={2}>
        <TradeDetail
          label={t("tradeInformation.orderQty")}
          value={orderResponse.order.qty}
          testId="tradeInformation.orderQty"
        />
        <TradeDetail
          label={t("tradeInformation.unitPrice")}
          value={<CurrentPriceValue symbol={symbol} />}
          testId="tradeInformation.unitPrice"
        />
        <TradeDetail
          label={t("tradeInformation.currentPosition")}
          value={<PortfolioValue />}
          testId="tradeInformation.currentPosition"
        />
        <TradeDetail
          label={t("tradeInformation.timeStamp")}
          value={formatDate(orderResponse.order.createdAt)}
          testId="tradeInformation.timeStamp"
        />
        <TradeDetail
          label={t("tradeInformation.orderRef")}
          value={orderResponse.order.clientOrderId}
          testId="tradeInformation.orderRef"
        />
      </Stack>
    </Stack>
  )
}
