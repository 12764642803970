export const AppHeaderLocators = {
  main: "AppHeaderLocators.main",
  expanded: "AppHeaderLocators.expanded",
  accountName: "AppHeaderLocators.accountName",
  accountInfo: "AppHeaderLocators.accountInfo",
  portfolioBalance: "AppHeaderLocators.portfolioBalance",
  cashBalance: "AppHeaderLocators.cashBalance",
  totalGainLoss: "AppHeaderLocators.totalGainLoss",
  dailyGainLoss: "AppHeaderLocators.dailyGainLoss",
}
