import { bind } from "@react-rxjs/core"

import { OrderHistory, OrderHistoryService } from "../TradingGateway"
import { WSEndPoint } from "../utils/registerWSService$"
import { scanPagingEvents } from "../utils/scanPagingEvents"

export const [useOrderHistory] = bind<OrderHistory[]>(
  OrderHistoryService.getOrders().pipe(
    scanPagingEvents<OrderHistory>(WSEndPoint.getOrders),
  ),
)
