import { Divider, Skeleton, Stack } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"

import { SummaryHeader } from "../SummaryHeader"
import { PositionsGrid } from "./PositionsGrid"

export const Positions = () => {
  return (
    <Stack flexGrow={1} padding={4} gap={2.5} data-testid="MyPositions">
      <SummaryHeader title="myPositions" />
      <Divider sx={{ height: "4px" }} />
      <Subscribe fallback={<Skeleton variant="rounded" height="100%" />}>
        <PositionsGrid />
      </Subscribe>
    </Stack>
  )
}
