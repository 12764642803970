import { Stack, Typography } from "@mui/material"
import { ReactElement } from "react"

import { useIsDesktop } from "@/shared/utils"

interface DetailFieldProps {
  label: string
  children: ReactElement | string
  testId: string
}

export const DetailField = ({ label, children, testId }: DetailFieldProps) => {
  const isDesktop = useIsDesktop()

  return (
    <Stack
      direction="row"
      gap={2}
      minHeight={isDesktop ? "auto" : 28}
      alignItems="center"
    >
      <Typography variant="Text md/Regular" color="text.tertiary">
        {label}:
      </Typography>
      <Typography
        variant="Text lg/Regular"
        color="text.primary"
        data-testid={testId}
      >
        {children}
      </Typography>
    </Stack>
  )
}
