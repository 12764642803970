import { Subscribe } from "@react-rxjs/core"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"

import { SimpleViewLayout } from "@/mobile/layout"
import { RootPath } from "@/mobile/paths"
import {
  ErrorBoundary,
  INSTRUMENT_PAGE_LOCATORS,
  TradeButton,
} from "@/shared/components"
import { Direction } from "@/shared/services/orders"

import { TradeTicket } from "../tradeScreen"
import { TradeReceipt } from "../tradeScreen/TradeReceipt"
import { BottomBar } from "../utils"
import { InstrumentChart } from "./InstrumentChart"
import { InstrumentDetails } from "./InstrumentDetails"
import { InstrumentHeader } from "./InstrumentHeader"

export const InstrumentPage = () => {
  const { t } = useTranslation()

  const { symbol } = useParams()
  if (!symbol) {
    throw Error()
  }

  return (
    <SimpleViewLayout
      data-testid={INSTRUMENT_PAGE_LOCATORS.main}
      back={{ to: RootPath.trade }}
      header={
        <InstrumentHeader
          symbol={symbol}
          data-testid={INSTRUMENT_PAGE_LOCATORS.root}
        />
      }
      routePattern={RootPath.tradeInstrument.routePattern}
      childRoutes={[
        {
          path: RootPath.tradeInstrumentTrade.routePattern,
          element: <TradeTicket />,
        },
        {
          path: RootPath.tradeInstrumentTradeReceipt.routePattern,
          element: <TradeReceipt />,
        },
      ]}
    >
      <ErrorBoundary fallback={<div>{t("errorLoadingInstrumentDetails")}</div>}>
        <InstrumentChart symbol={symbol} />
        <InstrumentDetails symbol={symbol} />
      </ErrorBoundary>
      <BottomBar>
        <Subscribe>
          <TradeButton
            direction={Direction.Buy}
            component={Link}
            to={RootPath.tradeInstrumentTrade(symbol, Direction.Buy)}
          />
          <TradeButton
            direction={Direction.Sell}
            component={Link}
            to={RootPath.tradeInstrumentTrade(symbol, Direction.Sell)}
          />
        </Subscribe>
      </BottomBar>
    </SimpleViewLayout>
  )
}
