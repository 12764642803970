import { Box } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { t } from "i18next"
import { PropsWithChildren } from "react"

import { ErrorBoundary, Loading, SearchBar } from "@/shared/components"
import { useSearchValue } from "@/shared/services/instruments/searchInstruments"

interface SearchControllerProps {
  searchResultComponent: React.ReactNode
  setSearchValue: (value: string) => void
  setIsSearchActive: (isSearchActive: boolean) => void
  isSearchActive: boolean
  "data-testid": string
}

type Props = PropsWithChildren<SearchControllerProps>

export const SearchController = ({
  children,
  searchResultComponent,
  setSearchValue,
  setIsSearchActive,
  isSearchActive,
  "data-testid": testId,
}: Props) => {
  const searchValue = useSearchValue()

  const activateSearch = () => {
    setIsSearchActive(true)
  }

  const deactivateSearch = () => {
    setIsSearchActive(false)
    setSearchValue?.("")
  }

  return (
    <>
      <Box gap={2} padding={2}>
        <SearchBar
          activateSearch={activateSearch}
          deactivateSearch={deactivateSearch}
          isActive={isSearchActive}
          data-testid={testId}
          size="xs"
          searchIcon="searchdark"
        />
      </Box>

      {isSearchActive && !!searchValue ? (
        <ErrorBoundary fallback={<div>{t("errorLoadingInstruments")}</div>}>
          <Subscribe fallback={<Loading />}>{searchResultComponent}</Subscribe>
        </ErrorBoundary>
      ) : (
        children
      )}
    </>
  )
}
