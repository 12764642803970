import { Components, LinkProps, Theme } from "@mui/material"

import { LinkBehavior } from "./LinkBehavior"

export const MuiLink: Required<Components<Theme>>["MuiLink"] = {
  defaultProps: {
    // This supposedly doesn't exist in LinkProps, but works regardless.
    component: LinkBehavior,
  } as LinkProps,
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      color: palette.text.primary,
    }),
  },
}
