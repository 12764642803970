import { Stack } from "@mui/material"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

import {
  reset,
  setShowTradeReceipt,
  useResponse,
  useShowTradeReceipt,
} from "@/shared/services/orders"
import { OrderStatus } from "@/shared/services/TradingGateway"

import { OrderForm } from "./OrderForm"
import { OrderReceipt } from "./OrderReceipt"

export const OrderContainer = () => {
  const { isLoading, response } = useResponse()
  const showTradeReceipt = useShowTradeReceipt()
  const { symbol } = useParams()
  if (!symbol) {
    throw Error()
  }

  useEffect(() => {
    if (!isLoading && response) {
      if (response.type === OrderStatus.accepted) {
        reset()
        setShowTradeReceipt(true)
      }
    }
  }, [isLoading, response])

  return (
    <Stack
      flex="1 1 20%"
      minWidth={300}
      overflow="scroll"
      padding={2}
      borderRadius={1}
      gap={4}
      bgcolor="background.secondarySubtle"
    >
      {showTradeReceipt ? <OrderReceipt /> : <OrderForm symbol={symbol} />}
    </Stack>
  )
}
