import { Stack, styled, Tab, Tabs } from "@mui/material"
import { t } from "i18next"
import { ReactNode, SyntheticEvent, useCallback, useState } from "react"

import { OrderType, toggleOrderType } from "@/shared/services/orders"
import { useIsDesktop } from "@/shared/utils"

import { LimitOrderForm } from "./forms/LimitOrderForm"
import { MarketOrderForm } from "./forms/MarketOrderForm"
import { StopOrderForm } from "./forms/StopOrderForm"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface OrderTab {
  key: string
  label: string
  content: ReactNode
  onSelect: () => void
}

const ORDER_TABS: OrderTab[] = [
  {
    key: "market",
    label: t("orderTypeOptions.market"),
    content: <MarketOrderForm />,
    onSelect: () => toggleOrderType(OrderType.MarketOrder),
  },
  {
    key: "limit",
    label: t("orderTypeOptions.limit"),
    content: <LimitOrderForm />,
    onSelect: () => toggleOrderType(OrderType.LimitOrder),
  },
  {
    key: "stop",
    label: t("orderTypeOptions.stop"),
    content: <StopOrderForm />,
    onSelect: () => toggleOrderType(OrderType.StopOrder),
  },
]

const CustomTabPanel = ({ children, value, index }: TabPanelProps) => {
  return (
    <Stack
      role="tabpanel"
      spacing={4}
      sx={{
        display: value == index ? "flex" : "none",
      }}
    >
      {children}
    </Stack>
  )
}

const OrderTab = styled(Tab)(({ theme }) => {
  const isDesktop = useIsDesktop()

  return {
    ...theme.typography[isDesktop ? "Text md/Semibold" : "Text xl/Semibold"],
    textTransform: "capitalize",
  }
})

export const OrderTabs = () => {
  const isDesktop = useIsDesktop()
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabChange = useCallback((_: SyntheticEvent, index: number) => {
    const tab = ORDER_TABS[index]
    tab.onSelect()
    setTabIndex(index)
  }, [])

  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        sx={{
          flexShrink: 0,
          marginBottom: 2,
        }}
        centered={!isDesktop}
      >
        {ORDER_TABS.map(({ label, key }) => (
          <OrderTab label={label} key={key} />
        ))}
      </Tabs>

      {ORDER_TABS.map(({ key, content }, index) => (
        <CustomTabPanel value={tabIndex} index={index} key={key}>
          {content}
        </CustomTabPanel>
      ))}
    </>
  )
}
