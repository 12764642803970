import { Stack, styled, Tab } from "@mui/material"
import { t } from "i18next"
import { Link } from "react-router-dom"

import { RootPath } from "@/mobile/paths"
import { Icon, IconNames } from "@/shared/components"
import { useRouteMatch } from "@/shared/utils"

interface RouteTab {
  path: string
  icon: IconNames
  label: string
}

const ROUTE_TABS: Record<string, RouteTab> = {
  [RootPath.trade]: {
    path: RootPath.trade,
    icon: "eye",
    label: t("navigation.trade"),
  },
  [RootPath.positions]: {
    path: RootPath.positions,
    icon: "trend",
    label: t("navigation.positions"),
  },
  [RootPath.orderHistory]: {
    path: RootPath.orderHistory,
    icon: "frame",
    label: t("navigation.orders"),
  },
}

// additional styles to suppress ugly shadows in Light mode
// could refactor to BottomNavigation, rather than Tab with no Tabs parent ..
const NavLink = styled(Link)`
  "*": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
`

export const Navigation = () => {
  const currentRoute = useRouteMatch(Object.keys(ROUTE_TABS))
  if (currentRoute == null) {
    return null
  }

  const currentTab = ROUTE_TABS[currentRoute]

  return (
    <Stack
      direction="row"
      sx={{
        position: "fixed",
        height: "60px",
        width: "100%",
        bottom: 0,
        backgroundColor: ({ palette }) => `${palette.background.primary}`,
      }}
    >
      {Object.values(ROUTE_TABS).map(({ path, icon, label }) => {
        const isSelected = currentTab.path === path
        return (
          <Tab
            key={label}
            icon={
              <Icon
                name={icon}
                size="sm"
                color={isSelected ? "text.quaternaryOnBrand" : "currentColor"}
              />
            }
            sx={({ typography }) => ({
              ...typography["Text lg/Semibold"],
              lineHeight: "normal",
              display: "flex",
              width: "33%",
              color: isSelected ? "text.quaternaryOnBrand" : "currentColor",
              backgroundColor: "background.secondarySubtle",
            })}
            label={label}
            value={path}
            to={path}
            component={NavLink}
          />
        )
      })}
    </Stack>
  )
}
