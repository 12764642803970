import { Components, Theme } from "@mui/material"

export const MuiTypography: Required<Components<Theme>>["MuiTypography"] = {
  defaultProps: {
    variantMapping: {
      // Map the new variants to the appropriate HTML element
      "Display sm/Bold": "h1",
      "Text xl/Regular": "h2",
    },
  },
}
