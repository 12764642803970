import { bind } from "@react-rxjs/core"
import { combineLatest, map } from "rxjs"

import { InstrumentsService } from "../TradingGateway"
import { latestQuote$ } from "./latestQuote"

export const [useDailyBars, dailyBars$] = bind((symbol: string) =>
  InstrumentsService.getDailyBars({ symbol }),
)

export type PriceAndChange = {
  price: number
  change: number
  timestamp: string
  changeAmount: number
}

export const [usePriceAndChange, priceAndChange$] = bind<
  [string],
  PriceAndChange
>((symbol: string) =>
  combineLatest([dailyBars$(symbol), latestQuote$(symbol)]).pipe(
    map(([{ c, o, t }, { askPrice, bidPrice }]) => {
      const marketOpen = (askPrice || bidPrice || 0) > 0
      const changeAmount = marketOpen ? askPrice! - c : c - o
      const change = marketOpen ? changeAmount / c : changeAmount / o
      const price = marketOpen ? askPrice! : c

      return {
        price,
        change,
        timestamp: t,
        changeAmount,
      }
    }),
  ),
)
