const BASE_INSTRUMENT_PAGE_LOCATORS = "InstrumentPageLocators"

export const INSTRUMENT_PAGE_LOCATORS = {
  root: `${BASE_INSTRUMENT_PAGE_LOCATORS}`,
  main: `${BASE_INSTRUMENT_PAGE_LOCATORS}.main`,
  icon: `${BASE_INSTRUMENT_PAGE_LOCATORS}.icon`,
  displayName: `${BASE_INSTRUMENT_PAGE_LOCATORS}.displayName`,
  price: `${BASE_INSTRUMENT_PAGE_LOCATORS}.price`,
  buy: `${BASE_INSTRUMENT_PAGE_LOCATORS}.buyButton`,
  sell: `${BASE_INSTRUMENT_PAGE_LOCATORS}.sellButton`,
}
