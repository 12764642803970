import { useRegisterSW } from "virtual:pwa-register/react"

export const RegisterSW = () => {
  const { updateServiceWorker } = useRegisterSW({
    immediate: true,

    onNeedRefresh() {
      console.debug(`RT Equities - SW needs refresh`) // eslint-disable-line
      void updateServiceWorker(true)
    },

    // onRegisteredSW(swScriptUrl, _registration) {
    //   console.debug(
    //     `RT Equities RegisterSW v${version} registered at URL ${swScriptUrl}`,
    //   )
    // },

    onRegisterError(error) {
      console.error(`RT Equities - SW registration error: ${error}`)
    },
  })
  return null
}
