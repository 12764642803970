import { Stack, Typography } from "@mui/material"

import { InstrumentLogo } from "@/shared/components"
import { useInstrument } from "@/shared/services/instruments"

interface SymbolCellProps {
  symbol: string
  testidPrefix: string
}
export const SymbolCell = ({ symbol, testidPrefix }: SymbolCellProps) => {
  const displayName = useInstrument(symbol)?.displayName

  return (
    <Stack direction="row" alignItems="center">
      <Stack paddingRight={3}>
        <InstrumentLogo
          symbol={symbol}
          variant="md"
          data-testid={`${testidPrefix}.logo`}
        />
      </Stack>
      <Stack>
        <Typography variant="Text lg/Regular">{displayName}</Typography>
        <Typography variant="Text md/Regular" color="text.tertiary">
          {symbol}
        </Typography>
      </Stack>
    </Stack>
  )
}
