import { Box, Skeleton, Stack, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"

import { CurrentPriceValue, InstrumentLogo, Star } from "@/shared/components"
import { useInstrument } from "@/shared/services/instruments"

interface InstrumentHeaderProps {
  symbol: string
  "data-testid": string
}

export const InstrumentHeader = ({
  symbol,
  "data-testid": testId,
}: InstrumentHeaderProps) => {
  const displayName = useInstrument(symbol)?.displayName

  return (
    <Stack
      direction="row"
      gap={3}
      marginBottom={5}
      sx={{ alignItems: "center" }}
    >
      <InstrumentLogo symbol={symbol} data-testid={`${"testId"}.logo`} />
      <Stack flexGrow={1} gap={1} overflow="hidden">
        <Typography
          variant="Display sm/Regular"
          sx={{ flexGrow: 1, lineHeight: "normal" }}
          noWrap
          data-testid={`${testId}.displayName`}
        >
          <Subscribe fallback={<Skeleton />}>{displayName}</Subscribe>
        </Typography>
        <Typography variant="Text xl/Regular" sx={{ lineHeight: "normal" }}>
          <Subscribe fallback={<Skeleton />}>
            <CurrentPriceValue symbol={symbol} />
          </Subscribe>
        </Typography>
      </Stack>
      <Box sx={{ flexShrink: 0, width: 30, height: 30 }}>
        <Star />
      </Box>
    </Stack>
  )
}
