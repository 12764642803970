import { Typography } from "@mui/material"

import { usePriceAndChange } from "@/shared/services/instruments"
import { formatCurrency, formatPercentageSigned } from "@/shared/utils"

interface ValueProps {
  symbol: string
}

export const CurrentPriceValue = ({ symbol }: ValueProps) => {
  const { price } = usePriceAndChange(symbol)
  return formatCurrency(price)
}

export const ChangePercentage = ({ symbol }: ValueProps) => {
  const { change, changeAmount } = usePriceAndChange(symbol)
  return (
    <Typography
      variant="Text md/Regular"
      color={changeAmount < 0 ? "negative.main" : "positive.main"}
    >
      {formatPercentageSigned(change)}
    </Typography>
  )
}

export const ChangeAmount = ({ symbol }: ValueProps) => {
  const { changeAmount } = usePriceAndChange(symbol)
  return (
    <Typography
      variant="Text md/Regular"
      color={changeAmount < 0 ? "negative.main" : "positive.main"}
    >
      {formatCurrency(changeAmount)}
    </Typography>
  )
}
