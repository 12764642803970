import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid"
import { t } from "i18next"

import { SymbolCell } from "@/shared/components"
import { closePosition } from "@/shared/services/orders"
import { Position } from "@/shared/services/TradingGateway"
import { formatFloat, formatPercentageSigned } from "@/shared/utils"

import { POSITIONS_PAGE_LOCATORS } from "./locators"
import { PortfolioPercent } from "./PortfolioPercent"

const floatFormatter = (params: GridValueFormatterParams<number>) =>
  params.value == null ? "" : formatFloat(params.value)

export const positionsGridColumns: GridColDef[] = [
  {
    field: "symbol",
    headerName: t("symbol"),
    width: 200,
    sortable: false,
    renderCell: (params: GridRenderCellParams<Position, string>) =>
      params.value ? (
        <SymbolCell
          symbol={params.value}
          testidPrefix={`${POSITIONS_PAGE_LOCATORS.grid}.row.${params.value}`}
        />
      ) : null,
  },
  {
    field: "portfolioPercent",
    headerName: t("portfolioPercent"),
    width: 200,
    sortable: false,
    renderCell: (params: GridRenderCellParams<Position>) => {
      return <PortfolioPercent value={params.row.market_value} />
    },
  },
  {
    field: "change_today",
    headerName: t("24hChange"),
    type: "number",
    width: 120,
    sortable: false,
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value == null ? "" : formatPercentageSigned(params.value),
    cellClassName: (params: GridCellParams<Position, number>) =>
      !params.value || params.value == 0
        ? "none"
        : params.value > 0
          ? "positive"
          : "negative",
  },
  {
    field: "qty",
    headerName: t("quantity"),
    type: "number",
    sortable: false,
    valueFormatter: floatFormatter,
  },
  {
    field: "avg_entry_price",
    headerName: t("avgEntryPrice"),
    type: "number",
    width: 150,
    sortable: false,
    valueFormatter: floatFormatter,
  },
  {
    field: "market_value",
    headerName: t("valueInUsd"),
    type: "number",
    width: 150,
    sortable: false,
    valueFormatter: floatFormatter,
  },
  {
    field: "actions",
    type: "actions",
    sortable: false,
    getActions: (params: GridRowParams<Position>) => [
      <GridActionsCellItem
        key="edit"
        label="Edit"
        onClick={() => {}}
        showInMenu
        data-testid="position.edit"
      />,
      <GridActionsCellItem
        key="close"
        label="Close"
        onClick={() => closePosition(params.row.symbol)}
        showInMenu
        data-testid="position.close"
      />,
    ],
  },
]
