import { Components, Theme } from "@mui/material"

export const MuiCssBaseline: Required<Components<Theme>>["MuiCssBaseline"] = {
  styleOverrides: ({ palette }) => ({
    body: {
      margin: 0,
      overflow: "hidden",
      scrollbarColor: `${palette.background.quaternary} transparent`,
    },
  }),
}
