import { Components, Theme } from "@mui/material"

export const MuiInputBase: Required<Components<Theme>>["MuiInputBase"] = {
  styleOverrides: {
    root: ({ theme: { palette, typography } }) => ({
      ...typography["Text md/Regular"],
      backgroundColor: palette.background.tertiary,
      // To set the placeholder color to something specific, use ::placeholder
      // Requires opacity: 1 to actually get the color you asked for in Chrome/Firefox at least
      // ref https://developer.mozilla.org/en-US/docs/Web/CSS/::placeholder#opaque_text
      ".MuiInputBase-input::placeholder": {
        color: palette.text.placeholder,
        opacity: 1,
      },
    }),
  },
}
