import { useContext } from "react"

import { themes } from "@/shared/theme"

import { ThemeContext, ThemeMode } from "./ThemeContext"

export const useTheme = () => {
  const { themeMode, setThemeMode } = useContext(ThemeContext)
  const toggleTheme = () =>
    setThemeMode((prevThemeMode) =>
      prevThemeMode === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK,
    )

  return { theme: themes[themeMode], themeMode, toggleTheme }
}
