import {
  createRESTService$,
  RestEndPoint,
  RestService,
} from "../utils/createRESTRequest$"
import {
  instrumentUpdates$,
  registerWSService$,
  WSEndPoint,
  WSService,
} from "../utils/registerWSService$"

export interface Instrument {
  symbol: string
  name: string
  displayName: string
  exchange: string
  sector: string
  industry: string
  marketCapitalization: number
}

export interface RawQuote {
  ap: number //askPrice
  bp: number //bidPrice
  t: string //timeStamp
}

export interface RawDailyBar {
  o: number //openPrice
  h: number //highPrice
  l: number //lowPrice
  c: number //closePrice
  v: number //volume
  t: string //timestamp
}

export interface RawHistoricalBar {
  o: number //openPrice
  h: number //highPrice
  l: number //lowPrice
  c: number //closePrice
  v: number //volume
  n: number //tradeAmount
  vw: number //vwap
  t: string //timestamp
}

export interface InstrumentsServiceSchema {
  getInstruments: WSService<WSEndPoint.getInstruments>
  getLatestQuote: WSService<WSEndPoint.getLatestQuote>
  getDailyBars: WSService<WSEndPoint.getDailyBars>
  getHistoricalBars: RestService<RestEndPoint.getHistoricalBar>
}

export const InstrumentsService: InstrumentsServiceSchema = {
  getInstruments: () => instrumentUpdates$,
  getLatestQuote: registerWSService$(WSEndPoint.getLatestQuote),
  getDailyBars: registerWSService$(WSEndPoint.getDailyBars),
  getHistoricalBars: createRESTService$(RestEndPoint.getHistoricalBar),
}
