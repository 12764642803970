const BASE_TRADE_TICKET_LOCATORS = "TradeTicketLocators"

export const TRADE_TICKET_LOCATORS = {
  root: `${BASE_TRADE_TICKET_LOCATORS}`,
  closeButton: `${BASE_TRADE_TICKET_LOCATORS}.closeButton`,
  availableBalance: `${BASE_TRADE_TICKET_LOCATORS}.availableBalance`,
  currentPosition: `${BASE_TRADE_TICKET_LOCATORS}.currentPosition`,
  pAndL: `${BASE_TRADE_TICKET_LOCATORS}.pAndL`,
  tradeButton: `${BASE_TRADE_TICKET_LOCATORS}.tradeButton`,
}
