import { Components, Theme } from "@mui/material"

export const MuiButton: Required<Components<Theme>>["MuiButton"] = {
  defaultProps: {
    variant: "contained",
    // color primary is default, I know, but making explicit as we are deriving other CSS (like hover) off it
    color: "primary",
  },
  styleOverrides: {
    root: ({ theme: { constants, palette, typography } }) => ({
      ...typography["Text md/Semibold"],
      height: `${constants.buttonHeight}px`,
      borderRadius: `${constants.buttonHeight / 2}px`,
      flexGrow: 1,
      "&:hover": {
        color: palette.getContrastText(palette.primary.dark),
      },
    }),
  },
}
