import { Components, Theme } from "@mui/material"

export const MuiTableCell: Required<Components<Theme>>["MuiTableCell"] = {
  styleOverrides: {
    root: ({ theme: { palette, spacing, typography } }) => ({
      ...typography["Text md/Regular"],
      padding: spacing(1.5),
      borderBottom: `1px solid ${palette.background.tertiary}`,
    }),
  },
}
