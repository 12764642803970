import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import { LoginForm } from "@/shared/components"

export const Login = () => {
  const { t } = useTranslation()

  return (
    <Stack height="100vh" alignItems="center" position="relative">
      <Stack maxWidth="450px" gap={3} position="absolute" top="25%">
        <Typography variant="Display lg/Semibold">{t("login")}</Typography>
        <LoginForm />
      </Stack>
    </Stack>
  )
}
