import { Components, Theme } from "@mui/material"

export const MuiLinearProgress: Required<
  Components<Theme>
>["MuiLinearProgress"] = {
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      borderRadius: "999px",
      backgroundColor: palette.background.secondarySubtle,
    }),
    bar: {
      borderRadius: "999px",
    },
  },
}
