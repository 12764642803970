import { Skeleton, Stack, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"

import { InstrumentLogo } from "@/shared/components"
import { useInstrument } from "@/shared/services/instruments"

import { LogoVariant } from "./InstrumentLogo"

interface InstrumentTitleProps {
  symbol: string
  logoVariant: LogoVariant
  testId: string
}
export const InstrumentTitle = ({
  symbol,
  logoVariant,
  testId,
}: InstrumentTitleProps) => {
  const displayName = useInstrument(symbol)?.displayName

  return (
    <Stack
      direction="row"
      gap={logoVariant === "md" ? 2 : 4}
      alignItems="center"
      flex="1 1"
    >
      <InstrumentLogo
        variant={logoVariant}
        symbol={symbol}
        data-testid={`${"testId"}.logo`}
      />
      <Stack flex="1 1">
        <Typography
          variant="Text lg/Regular"
          maxWidth={160}
          data-testid={`${testId}.displayName`}
        >
          <Subscribe fallback={<Skeleton />}>{displayName}</Subscribe>
        </Typography>
        <Typography
          variant="Text sm/Regular"
          color="text.tertiary"
          data-testid={`${testId}.symbol`}
        >
          {symbol}
        </Typography>
      </Stack>
    </Stack>
  )
}
