import { useTheme } from "@mui/material"
import { useEffect, useRef } from "react"

type GetChangeColorOptions =
  | {
      previous: number | undefined
      current: number
    }
  | {
      change: number
    }

const getChangeColor = (
  options: GetChangeColorOptions,
): "positive" | "negative" | undefined => {
  let change: number
  if ("current" in options) {
    if (options.previous == null) {
      return
    }
    change = options.current - options.previous
  } else {
    change = options.change
  }
  if (change >= 0) {
    return "positive"
  }

  return "negative"
}

export const getChangeTextColor = (
  options: GetChangeColorOptions,
): "positive.main" | "negative.main" | undefined => {
  const color = getChangeColor(options)

  return color ? `${color}.main` : undefined
}

export type UseChangeColorOptions = {
  fallback: "secondary" | "primary"
} & (
  | {
      current: number | undefined
    }
  | {
      change: number
    }
)

export const useChangePaletteColor = ({
  fallback,
  ...options
}: UseChangeColorOptions): string => {
  const theme = useTheme()
  const previousPrice = useRef<number>()

  let getChangeOptions: GetChangeColorOptions

  useEffect(() => {
    if ("current" in options) {
      previousPrice.current = options.current
    }
  })

  if ("current" in options) {
    getChangeOptions = {
      current: options.current ?? 0,
      previous: previousPrice.current,
    }
  } else {
    getChangeOptions = options
  }

  const color = getChangeColor(getChangeOptions)

  return theme.palette[color ?? fallback]!.main
}
