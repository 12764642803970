import { LinearProgress, Stack } from "@mui/material"

import { usePositionsValue } from "@/shared/services/positions"
import { formatPercentage } from "@/shared/utils"

export const PortfolioPercent = ({ value }: { value: string }) => {
  const totalValue = usePositionsValue()
  const percent = parseFloat(value) / totalValue
  const percentFormatted = formatPercentage(percent)
  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <LinearProgress
        variant="determinate"
        value={percent * 100}
        sx={({ palette }) => ({
          width: "60%",
          backgroundColor: palette.background.quaternary,
          "& .MuiLinearProgress-bar": {
            backgroundColor: palette.text.brandPrimary,
          },
        })}
      />
      <Stack>{percentFormatted}</Stack>
    </Stack>
  )
}
