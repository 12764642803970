import { useInstrument } from "@/shared/services/instruments"
import { Instrument } from "@/shared/services/TradingGateway"

interface InstrumentFieldProps<Field extends keyof Instrument> {
  symbol: string
  field: Field
  formatter?: (input: Instrument[Field]) => string
}

export const InstrumentInfoDataPoint = ({
  symbol,
  field,
  formatter,
}: InstrumentFieldProps<keyof Instrument>) => {
  let attribute = useInstrument(symbol)?.[field]
  if (formatter && attribute) {
    attribute = formatter(attribute)
  }
  return attribute
}
