import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import { AeronLogo } from "@/shared/components"

// import { AERON } from "@/client/constants"
const aeronUrl = "https://aeron.io"

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" height={20} paddingX={4}>
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        onClick={() => window.open(aeronUrl)}
      >
        <Typography variant="Text xs/Regular" color="text.quaternary">
          {t("powered")}
        </Typography>
        <AeronLogo height={10} />
      </Stack>
    </Stack>
  )
}
