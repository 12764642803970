import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { CONNECTION_LOST_LOCATORS } from "./locators"
import { isOfflineObservable$, useIsOffline } from "./state"

export const ConnectionLost = () => {
  return (
    <Subscribe source$={isOfflineObservable$}>
      <ConnectionLostInternal />
    </Subscribe>
  )
}

export const ConnectionLostInternal = () => {
  const { t } = useTranslation()
  const isOffline = useIsOffline()
  const [showOffline, setShowOffline] = useState(false)

  useEffect(() => {
    if (isOffline) {
      setShowOffline(true)
    }
  }, [isOffline])

  return (
    <Dialog open={showOffline} data-testid={CONNECTION_LOST_LOCATORS.root}>
      <DialogTitle>{t("connectionLost")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("pleaseReload")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => window.location.reload()}>{t("reload")}</Button>
      </DialogActions>
    </Dialog>
  )
}
