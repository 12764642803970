import {
  registerWSService$,
  WSEndPoint,
  WSService,
} from "../utils/registerWSService$"
import { Instrument } from "."

export interface WatchList {
  id: string
  account_id: string
  created_at: string
  updated_at: string
  name: string
  assets: Instrument[]
}

export interface WatchListServiceSchema {
  getWatchList: WSService<WSEndPoint.getWatchList>
}

export const WatchListService: WatchListServiceSchema = {
  getWatchList: registerWSService$(WSEndPoint.getWatchList),
}
