import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"

import { DialogParams } from "@/shared/services/dialog/dialog.service"

type Props = DialogParams

export const ConfirmDialog = ({
  isOpen,
  title,
  description,
  onCancel,
  onConfirm,
  cancelLabel,
  confirmLabel,
}: Props) => {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "80%", maxHeight: 435, padding: 3 },
      }}
      maxWidth="xs"
      open={isOpen}
      onClose={onCancel}
    >
      <DialogTitle fontSize={20}>{title}</DialogTitle>

      {description && <DialogContent>{description}</DialogContent>}

      <DialogActions>
        <Button autoFocus onClick={onCancel}>
          {cancelLabel ?? "Cancel"}
        </Button>
        <Button onClick={onConfirm} color="warning">
          {confirmLabel ?? "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
