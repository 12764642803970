import { Stack } from "@mui/material"
import { useEffect } from "react"

import { Chart, setCurrentSymbol, TimeframeSelector } from "@/shared/components"

interface Props {
  symbol: string
}

export const InstrumentChart = ({ symbol }: Props) => {
  useEffect(() => {
    setCurrentSymbol(symbol)

    return () => setCurrentSymbol(undefined)
  }, [symbol])

  return (
    <Stack gap={1}>
      <Chart />
      <TimeframeSelector />
    </Stack>
  )
}
