import { Divider, Link, MenuItem, Select, Stack, styled } from "@mui/material"

import { rtFxCrBaseUrl } from "@/constants"
import { Logo, ThemeToggle } from "@/shared/components"
import { useIsDesktop } from "@/shared/utils"

import { AccountSummary } from "./AccountSummary"
import { Navigation } from "./Navigation"

const HeaderSeparator = styled(Divider)(
  ({
    theme: {
      palette: { background },
      spacing,
    },
  }) => ({
    borderColor: background.quaternary,
    height: "calc(100% - 20px)",
    margin: `0 ${spacing(3)}`,
  }),
)

export interface AssetItem {
  label: string
  href: string
  value: string
}

const assetSelections: AssetItem[] = [
  { label: "FX", href: rtFxCrBaseUrl, value: "fx" },
  { label: "Credit", href: `${rtFxCrBaseUrl}/credit`, value: "cr" },
  { label: "Equities", href: "#", value: "eq" },
]
interface AssetSelectorProps {
  options: AssetItem[]
  name: string
  value: string
}

const AssetSelector = ({ name, options, value }: AssetSelectorProps) => (
  <Select
    variant="standard"
    disableUnderline={true}
    name={name}
    value={value}
    sx={({ typography }) => ({
      ...typography["Display xs/Regular"],
      backgroundColor: "transparent",
      ".MuiSelect-select:focus": {
        backgroundColor: "transparent",
      },
      ".MuiSelect-icon": {
        color: "text.quaternaryOnBrand",
      },
    })}
    MenuProps={{
      sx: {
        ".MuiList-root": {
          backgroundColor: "background.primaryAlt",
        },
      },
    }}
  >
    {options.map(({ label, href, value }) => (
      <MenuItem
        key={label}
        value={value}
        sx={({ typography }) => ({
          ...typography["Display xs/Regular"],
          padding: 0,
          "&.Mui-selected": {
            backgroundColor: "unset",
          },
          "&.Mui-selected *": {
            color: "text.quaternaryOnBrand",
          },
        })}
      >
        <Link
          href={href}
          underline="none"
          paddingX={4}
          paddingY={1}
          width="100%"
        >
          {label}
        </Link>
      </MenuItem>
    ))}
  </Select>
)

export const Header = () => {
  const isDesktop = useIsDesktop()

  return (
    <Stack
      direction="row"
      alignItems="center"
      minHeight={({ constants }) => constants.desktopHeaderHeight}
      borderRadius={1}
      px={3.5}
      gap={2}
    >
      <Stack flexBasis={1}>
        <Logo />
      </Stack>
      <HeaderSeparator
        orientation="vertical"
        variant="middle"
        aria-hidden="true"
        color="background.quaternary"
      />
      {isDesktop && (
        <AssetSelector
          options={assetSelections}
          value={"eq"}
          data-testid="timeinForce"
          name="timeInForce"
        />
      )}
      <Stack flexBasis={1} flexGrow={1} alignItems="center">
        <Navigation />
      </Stack>
      <Stack flexBasis={1} direction="row" alignItems="center" gap={2}>
        <ThemeToggle />
        <AccountSummary />
      </Stack>
    </Stack>
  )
}
