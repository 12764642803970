import "./shared/utils/i18n"

import { CssBaseline } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { StrictMode } from "react"
import { BrowserRouter } from "react-router-dom"

import { DesktopMobileSwitch } from "./DesktopMobileSwitch"
import { RegisterSW } from "./RegisterSW"
import { AppRoutes } from "./Routes"
import { ConnectionLost, ThemeProvider } from "./shared/components"
import { Authenticated } from "./shared/services/Authenticated"

export default function App() {
  const buildVersion = import.meta.env.VITE_BUILD_VERSION as string
  // eslint-disable-next-line no-console
  console.debug(`Running Alpaca Ref UI build ${buildVersion}`)

  return (
    <StrictMode>
      <RegisterSW />
      <ConnectionLost />
      <ThemeProvider>
        <CssBaseline />
        <BrowserRouter>
          <Subscribe fallback={<></>}>
            <Authenticated>
              <DesktopMobileSwitch />
              <AppRoutes />
            </Authenticated>
          </Subscribe>
        </BrowserRouter>
      </ThemeProvider>
    </StrictMode>
  )
}
