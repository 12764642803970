import { Stack } from "@mui/material"

import {
  InfoField,
  instrumentInfo,
  InstrumentInfoDataPoint,
  QuoteDataPoint,
  QuoteDataPointField,
} from "@/shared/components"
import { Instrument } from "@/shared/services/TradingGateway"

interface Props {
  symbol: string
}

export const InstrumentDetails = ({ symbol }: Props) => {
  return (
    <Stack direction="column" paddingX={4} gap={4}>
      {instrumentInfo.map((info) => {
        const { label, type, field } = info

        return (
          <InfoField
            label={label}
            labelVariant="Text lg/Semibold"
            key={field}
            testId={field}
          >
            {type === "quote" && (
              <QuoteDataPoint
                field={field as QuoteDataPointField}
                symbol={symbol}
              />
            )}

            {type === "instrumentInfo" && (
              <InstrumentInfoDataPoint
                field={field as keyof Instrument}
                symbol={symbol}
                formatter={info.formatter}
              />
            )}
          </InfoField>
        )
      })}
    </Stack>
  )
}
