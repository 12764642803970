import { Button, styled, TextField } from "@mui/material"

import { Direction } from "@/shared/services/orders"

export const OrderTextField = styled(TextField)(
  ({
    theme: {
      palette: { text },
    },
  }) => ({
    input: {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
      },
      MozAppearance: "textfield",
      color: text.primary,
      padding: "0 8px",
    },
    label: {
      // otherwise label changes color when input receives focus
      "&.Mui-focused": {
        color: text.secondary,
      },
      color: text.secondary,
    },
  }),
)

export const StyledSubmitOrderButton = styled(Button)<{ direction: Direction }>(
  ({ theme, direction }) => ({
    fontSize: 15,
    height: 36,
    border: "1px solid transparent",
    borderRadius: theme.spacing(2),
    flexShrink: "1",
    "&:hover": {
      color: theme.palette.getContrastText(theme.palette[direction].dark),
    },
    "&.MuiButtonBase-root:disabled": {
      color: theme.palette.action.disabled,
      backgroundColor: "transparent",
      borderColor: theme.palette.action.disabledBackground,
      borderStyle: "solid",
    },
  }),
)
