import { Subscribe } from "@react-rxjs/core"
import { useTranslation } from "react-i18next"

import { ErrorBoundary } from "@/shared/components"
import { useWatchList } from "@/shared/services/watchList"

import { Loading } from "../Loading"
import { InstrumentsTable } from "./InstrumentsTable"
import { TRADE_PAGE_LOCATORS } from "./locators"

const FeaturedInstrumentsTable = () => {
  const instruments = useWatchList()[0].assets
  return (
    <InstrumentsTable
      instruments={instruments}
      data-testid={TRADE_PAGE_LOCATORS.featured}
    />
  )
}

export const FeaturedInstruments = () => {
  const { t } = useTranslation()
  return (
    <ErrorBoundary fallback={<div>{t("errorLoadingInstruments")}</div>}>
      <Subscribe fallback={<Loading />}>
        <FeaturedInstrumentsTable />
      </Subscribe>
    </ErrorBoundary>
  )
}
