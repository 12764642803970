import { Stack, Tab, Tabs } from "@mui/material"
import { SyntheticEvent, useState } from "react"

export const ToggleChartHeaderGroup = () => {
  const [current, update] = useState("Chart")

  const options = ["Chart", "News", "Events"]

  const handleChange = (
    _event: SyntheticEvent<Element, Event>,
    value: string,
  ) => {
    if (value) {
      update(value)
    }
  }

  return (
    <Stack alignItems="left" px={4} py={2.25}>
      <Tabs value={current} onChange={handleChange} sx={{ width: "100%" }}>
        {options.map((option) => (
          <Tab key={option} label={option} value={option}></Tab>
        ))}
      </Tabs>
    </Stack>
  )
}
