import { createSignal } from "@react-rxjs/utils"
import { Observable, switchMap } from "rxjs"

import { eventBus, serviceStreams$ } from "../connection"
import {
  AccountSummary,
  Instrument,
  OrderHistory,
  Position,
  RawDailyBar,
  RawQuote,
  WatchList,
} from "../TradingGateway"

export enum WSEndPoint {
  getInstruments = "instruments",
  getPositions = "positions",
  getLatestQuote = "quotes",
  getDailyBars = "dailyBars",
  getAccountSummary = "accountSummaries",
  getOrders = "orders",
  getWatchList = "watchListDetails",
}

type Request = {
  [WSEndPoint.getInstruments]: undefined
  [WSEndPoint.getPositions]: undefined
  [WSEndPoint.getLatestQuote]: { symbol: string }
  [WSEndPoint.getDailyBars]: { symbol: string }
  [WSEndPoint.getAccountSummary]: undefined
  [WSEndPoint.getOrders]: undefined
  [WSEndPoint.getWatchList]: undefined
}

type Response = {
  [WSEndPoint.getInstruments]: PaginationEvent<Instrument>
  [WSEndPoint.getPositions]: PaginationEvent<Position>
  [WSEndPoint.getLatestQuote]: RawQuote
  [WSEndPoint.getDailyBars]: RawDailyBar
  [WSEndPoint.getAccountSummary]: AccountSummary
  [WSEndPoint.getOrders]: PaginationEvent<OrderHistory>
  [WSEndPoint.getWatchList]: PaginationEvent<WatchList>
}

export const CREATED_PAGINATION_EVENT = "created",
  UPDATED_PAGINATION_EVENT = "updated",
  SNAPSHOT_PAGINATION_EVENT = "snapshot",
  START_OF_SNAPSHOT_PAGINATION_EVENT = "start",
  END_OF_SNAPSHOT_PAGINATION_EVENT = "end",
  END_OF_PAGE_PAGINATION_EVENT = "end-of-page",
  DELETED_PAGINATION_EVENT = "deleted"

export type PaginationEventType =
  | typeof CREATED_PAGINATION_EVENT
  | typeof UPDATED_PAGINATION_EVENT
  | typeof SNAPSHOT_PAGINATION_EVENT
  | typeof START_OF_SNAPSHOT_PAGINATION_EVENT
  | typeof END_OF_SNAPSHOT_PAGINATION_EVENT
  | typeof END_OF_PAGE_PAGINATION_EVENT
  | typeof DELETED_PAGINATION_EVENT

export interface StartOfSnapshotPaginationEvent {
  type: typeof START_OF_SNAPSHOT_PAGINATION_EVENT
  size: number
}

export interface EndOfSnapshotPaginationEvent {
  type: typeof END_OF_SNAPSHOT_PAGINATION_EVENT
}

interface ItemPaginationEvent<T> {
  item: T
}

export interface SnapshotPaginationEvent<T> extends ItemPaginationEvent<T> {
  type: typeof SNAPSHOT_PAGINATION_EVENT
}

export interface UpdatedPaginationEvent<T> extends ItemPaginationEvent<T> {
  type: typeof UPDATED_PAGINATION_EVENT
}

export interface CreatedPaginationEvent<T> extends ItemPaginationEvent<T> {
  type: typeof CREATED_PAGINATION_EVENT
}

export interface EndOfPagePaginationEvent {
  type: typeof END_OF_PAGE_PAGINATION_EVENT
  continuationToken: string
}

export interface DeletedPaginationEvent {
  type: typeof DELETED_PAGINATION_EVENT
  symbol: string
}

export type PaginationEvent<T = undefined> =
  | StartOfSnapshotPaginationEvent
  | EndOfSnapshotPaginationEvent
  | SnapshotPaginationEvent<T>
  | UpdatedPaginationEvent<T>
  | CreatedPaginationEvent<T>
  | EndOfPagePaginationEvent
  | DeletedPaginationEvent

interface Message<T = undefined> {
  body: T
}

export type WSService<E extends WSEndPoint> = Request[E] extends undefined
  ? () => Observable<Response[E]>
  : (args: Request[E]) => Observable<Response[E]>

export function registerWSService$<E extends WSEndPoint>(
  endpoint: E,
): WSService<E> {
  return (args?: Request[E]) => {
    return serviceStreams$.pipe(
      switchMap((services) => {
        return new Observable<Response[E]>((subscriber) => {
          const { streaming: _streaming, cmd } = services[endpoint]

          const streaming = args
            ? Object.entries(args).reduce((acc, [key, value]) => {
                return acc.replace(`{${key}}`, value)
              }, _streaming)
            : _streaming

          const callback = (_error: object, message: Message<Response[E]>) => {
            subscriber.next(message.body)
          }

          eventBus.registerHandler(streaming, callback)

          if (cmd) {
            eventBus.send(cmd, { type: "subscribe" })
          }

          return () => {
            try {
              eventBus.unregisterHandler(streaming, callback)
            } catch {
              // nothing we can do here, the event bus is broken (most likely closed)
            }
          }
        })
      }),
    )
  }
}

//TODO: Remove when instruments implements normal service
export const [instrumentUpdates$, setInstrumentUpdate] =
  createSignal<PaginationEvent<Instrument>>()

export const registerInstrumentService = (
  message: Record<string, { streaming: string; cmd: string }>,
) => {
  eventBus.registerHandler(
    message[WSEndPoint.getInstruments].streaming,
    (_error: object, update: Message<PaginationEvent<Instrument>>) => {
      setInstrumentUpdate(update.body)
    },
  )

  eventBus.send(message[WSEndPoint.getInstruments].cmd, {
    type: "subscribe",
  })
}
