import { Stack } from "@mui/material"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { Chart, setCurrentSymbol, TimeframeSelector } from "@/shared/components"
import { formatFloat } from "@/shared/utils"

import { InfoField } from "../../../shared/components/InfoField"
import { DailyBarValue } from "./DailyBarValue"

interface InstrumentChartProps {
  symbol: string
}

export const InstrumentChart = ({ symbol }: InstrumentChartProps) => {
  useEffect(() => {
    setCurrentSymbol(symbol)

    return () => setCurrentSymbol(undefined)
  }, [symbol])

  return (
    <Stack data-testid="InstrumentCharts">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingX={4}
        paddingY={1}
      >
        <Ohlc symbol={symbol} />
        <TimeframeSelector />
      </Stack>
      <Chart />
    </Stack>
  )
}

interface OhlcProps {
  symbol: string
}

const Ohlc = ({ symbol }: OhlcProps) => {
  const { t } = useTranslation()
  return (
    <Stack direction="row" gap={6}>
      <InfoField
        label={t("open")}
        testId="open"
        direction="row"
        labelVariant="Text md/Regular"
        valueVariant="Text md/Regular"
      >
        <DailyBarValue symbol={symbol} field="o" formatter={formatFloat} />
      </InfoField>
      <InfoField
        label={t("high")}
        testId="high"
        direction="row"
        labelVariant="Text md/Regular"
        valueVariant="Text md/Regular"
      >
        <DailyBarValue symbol={symbol} field="h" formatter={formatFloat} />
      </InfoField>
      <InfoField
        label={t("low")}
        testId="low"
        direction="row"
        labelVariant="Text md/Regular"
        valueVariant="Text md/Regular"
      >
        <DailyBarValue symbol={symbol} field="l" formatter={formatFloat} />
      </InfoField>
      <InfoField
        label={t("close")}
        testId="close"
        direction="row"
        labelVariant="Text md/Regular"
        valueVariant="Text md/Regular"
      >
        <DailyBarValue symbol={symbol} field="c" formatter={formatFloat} />
      </InfoField>
    </Stack>
  )
}
