import { Stack, Typography } from "@mui/material"
import { ReactNode } from "react"

import { useIsDesktop } from "@/shared/utils"

interface TradeDetailProps {
  label: string
  value: string | number | ReactNode
  direction?: "row" | "column"
  testId: string
}

export const TradeDetail = ({
  label,
  value,
  direction,
  testId,
}: TradeDetailProps) => {
  const isDesktop = useIsDesktop()

  const labelVariant = isDesktop ? "Text md/Regular" : "Text xl/Regular"
  const valueVariant = isDesktop ? "Text lg/Semibold" : "Text xl/Regular"

  return (
    <Stack direction={direction} justifyContent="space-between">
      <Typography variant={labelVariant} color="text.secondary">
        {label}
      </Typography>
      <Typography variant={valueVariant} data-testid={testId}>
        {value}
      </Typography>
    </Stack>
  )
}
