import {
  IconButton,
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
  StandardTextFieldProps,
  TextField,
} from "@mui/material"
import { ChangeEvent } from "react"

import { useIsDesktop, validateInput } from "@/shared/utils"

import { Icon } from "../icons"
import { SelectItem } from "./forms/timeInForceSelections"
import { OrderTextField } from "./OrderForm.styled"

const DESKTOP_STYLES = {
  height: 28,
}

const MOBILE_STYLES = {
  height: 40,
  fontSize: 24,
}

export type OrderInputProps = Omit<StandardTextFieldProps, "onChange"> & {
  onChange: (validatedValue: string) => void
}

export const OrderInput = (props: OrderInputProps) => {
  const isDesktop = useIsDesktop()

  const { onChange, ...rest } = props

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const validated = validateInput(event.target.value)
    event.target.value = validated
    onChange(validated)
  }

  return (
    <OrderTextField
      type="number"
      placeholder="0.00"
      inputProps={{
        "data-testid": `input-${props.name}`,
        step: 0.001,
      }}
      onChange={changeHandler}
      sx={{ width: "100%" }}
      InputProps={{
        disableUnderline: true,
        sx: isDesktop ? DESKTOP_STYLES : MOBILE_STYLES,
        endAdornment: !isDesktop && (props.value as string) && (
          <InputAdornment position="end">
            <IconButton onClick={() => onChange("")}>
              <Icon color="text.secondary" name="close" size="md" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  )
}

interface OrderSelectProps {
  options: SelectItem[]
  name: string
  value: string
  label: string
  onChange: (e: SelectChangeEvent<unknown>) => void
}

export const OrderSelect = ({
  name,
  options,
  label,
  value,
  onChange,
}: OrderSelectProps) => {
  const isDesktop = useIsDesktop()

  return (
    <TextField
      select={true}
      label={label}
      SelectProps={{
        name,
        value,
        onChange,
        sx: ({ typography }) => ({
          ...typography[isDesktop ? "Text md/Regular" : "Display sm/Regular"],
        }),
      }}
      InputProps={{
        disableUnderline: true,
        sx: isDesktop ? DESKTOP_STYLES : MOBILE_STYLES,
      }}
    >
      {options.map(({ label, value }) => (
        <MenuItem
          key={value}
          value={value}
          sx={({ typography }) => ({
            ...typography[isDesktop ? "Text md/Regular" : "Display sm/Regular"],
          })}
        >
          {label}
        </MenuItem>
      ))}
    </TextField>
  )
}
