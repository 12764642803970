import { Skeleton } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useTranslation } from "react-i18next"

import {
  setLimitPrice,
  setLimitTimeInForce,
  setQty,
  setStopPrice,
  useLimitPriceValue,
  useQtyValue,
  useStopPriceValue,
  useTimeInForceValue,
} from "@/shared/services/orders"

import { OrderInput, OrderSelect } from "../OrderInputs"
import { timeInForceSelections } from "./timeInForceSelections"

export const StopOrderForm = () => {
  const { t } = useTranslation()

  const stopPriceValue = useStopPriceValue()
  const qtyValue = useQtyValue()
  const timeInForceValue = useTimeInForceValue()
  const limitPriceValue = useLimitPriceValue()

  return (
    <Subscribe fallback={<Skeleton />}>
      <OrderInput
        onChange={(value) => setQty(value)}
        value={qtyValue}
        name="marketQty"
        label={t("quantity")}
      />
      <OrderInput
        onChange={(value) => setLimitPrice(value)}
        value={limitPriceValue}
        name="limitPrice"
        label={t("limitPrice")}
      />
      <OrderInput
        onChange={(value) => setStopPrice(value)}
        value={stopPriceValue}
        name="stopPrice"
        label={t("stopPrice")}
      />
      <OrderSelect
        options={timeInForceSelections}
        onChange={(e) => setLimitTimeInForce(e.target.value as string)}
        value={timeInForceValue}
        name="timeInForce"
        label={t("timeInForce")}
      />
    </Subscribe>
  )
}
