import { Timeframe } from "./types"

type Periodicity = {
  period: number
  interval: number
  timeUnit: string
}

type TimeFrameConfig = Record<Timeframe, Periodicity>

export const timeframeConfig: TimeFrameConfig = {
  "10m": {
    period: 1,
    interval: 10,
    timeUnit: "minute",
  },
  "1h": {
    period: 1,
    interval: 60,
    timeUnit: "minute",
  },
  "1d": {
    period: 1,
    interval: 1,
    timeUnit: "day",
  },
}
