import { SplitHeaderLayout } from "@/mobile/layout"
import { RootPath } from "@/mobile/paths"
import {
  FeaturedInstruments,
  InstrumentsTable,
  TRADE_PAGE_LOCATORS,
} from "@/shared/components"
import {
  setSearchValue,
  useSearchResult,
} from "@/shared/services/instruments/searchInstruments"

import { AppHeader } from "../appHeader"
import { InstrumentPage } from "../instrumentPage"

const SearchResults = () => {
  const instruments = useSearchResult()
  return (
    <InstrumentsTable
      instruments={instruments}
      data-testid={TRADE_PAGE_LOCATORS.search}
    />
  )
}

export const Trade = () => (
  <SplitHeaderLayout
    data-testid={TRADE_PAGE_LOCATORS.main}
    routePattern={RootPath.trade}
    childRoutes={[
      {
        path: RootPath.tradeInstrument.routePattern,
        element: <InstrumentPage />,
      },
    ]}
    header={<AppHeader />}
    searchControllerOptions={{
      searchResultComponent: <SearchResults />,
      setSearchValue,
      "data-testid": TRADE_PAGE_LOCATORS.search,
    }}
  >
    <FeaturedInstruments />
  </SplitHeaderLayout>
)
