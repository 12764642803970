import { createSignal } from "@react-rxjs/utils"
import { shareReplay, startWith } from "rxjs"

import { ErrorTypes } from "../utils/errorTypes"

export enum ConnectionState {
  INIT = "init",
  DISCONNECTED = "disconnected",
  CONNECTED = "connected",
}

const [emailLogged$, setEmailLogged] = createSignal<string | null>()

const emailLoggedWithDefault$ = emailLogged$.pipe(
  startWith(null),
  shareReplay(1),
)

const [isErrorInLogin$, setErrorInLogin] = createSignal<ErrorTypes | null>()

const isErrorInLoginWithDefault$ = isErrorInLogin$.pipe(
  startWith(null),
  shareReplay(1),
)

const [eventBusConnectionState$, setEventBusConnectionState] =
  createSignal<ConnectionState>()

const eventBusConnectionStateWithDefault$ = eventBusConnectionState$.pipe(
  startWith(ConnectionState.INIT),
  shareReplay(1),
)

const [isEventBusTimeout$, setIsEventBusTimeout] = createSignal<boolean>()

const isEventBusTimeoutWithDefault$ = isEventBusTimeout$.pipe(
  startWith(false),
  shareReplay(1),
)

export {
  emailLoggedWithDefault$ as emailLogged$,
  eventBusConnectionStateWithDefault$ as eventBusConnectionState$,
  isErrorInLoginWithDefault$ as isErrorInLogin$,
  isEventBusTimeoutWithDefault$ as isEventBusTimeout$,
  setEmailLogged,
  setErrorInLogin,
  setEventBusConnectionState,
  setIsEventBusTimeout,
}
