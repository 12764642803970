import {
  registerWSService$,
  WSEndPoint,
  WSService,
} from "../utils/registerWSService$"

export interface Position {
  asset_id: string
  symbol: string
  exchange: string
  asset_class: string
  avg_entry_price: string
  qty: string
  qty_available: string
  side: string
  market_value: string
  cost_basis: string
  unrealized_pl: string
  unrealized_plpc: string
  unrealized_intraday_pl: string
  unrealized_intraday_plpc: string
  current_price: string
  lastday_price: string
  change_today: string
  // asset_marginable: string // trader API
  // swap_rate: string // broker API optional
  // avg_entry_swap_rate: string // broker API optional
}

export interface PositionsServiceSchema {
  getPositions: WSService<WSEndPoint.getPositions>
}

export const PositionsService: PositionsServiceSchema = {
  getPositions: registerWSService$(WSEndPoint.getPositions),
}
