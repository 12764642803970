import { Components, Theme } from "@mui/material"

export const MuiFormHelperText: Required<
  Components<Theme>
>["MuiFormHelperText"] = {
  styleOverrides: {
    root: ({ theme: { palette, typography } }) => ({
      ...typography["Text md/Semibold"],
      color: palette.text.secondary,
      marginLeft: 0,
      marginRight: 0,
    }),
  },
}
