import { Stack } from "@mui/material"
import { Navigate, Route, Routes } from "react-router-dom"

import { instruments$ } from "@/shared/services/instruments"
import { useWatchList } from "@/shared/services/watchList"
import { withSubscribe } from "@/shared/utils"

import { Footer } from "./components/Footer"
import { Header } from "./components/Header"
import { OrderHistory } from "./components/OrderHistory"
import { Positions } from "./components/Positions"
import { TradeMain } from "./components/Trade"
import { RootPath } from "./paths"

export const DesktopRoutes = withSubscribe(
  () => {
    const instruments = useWatchList()[0].assets
    const paddingGapAdjustment = 2 * 4 + 4

    return (
      <Stack height="100vh" overflow="scroll" padding={1} gap={1}>
        <Header />
        <Routes>
          <Route
            key="/"
            path="/*"
            element={
              <Navigate
                to={RootPath.tradeInstrument(instruments[0]?.symbol || "AAPL")}
                replace
              />
            }
          />
          <Route
            key="/trade"
            path="/trade/*"
            element={<TradeMain heightAdjustment={paddingGapAdjustment} />}
          />
          <Route key="/positions" path="/positions/*" element={<Positions />} />
          <Route
            key="/orderhistory"
            path="/orderHistory/*"
            element={<OrderHistory />}
          />
        </Routes>
        <Footer />
      </Stack>
    )
  },
  {
    source$: instruments$,
  },
)
