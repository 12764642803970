import { Box, Button, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useTranslation } from "react-i18next"
import {
  Link,
  Location,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"

import { LayoutContainer } from "@/mobile/layout"
import { RootPath } from "@/mobile/paths"
import { Direction } from "@/shared/services/orders"
import { Order } from "@/shared/services/TradingGateway"

import { BottomBar } from "../../utils"
import { AcceptedOrderNavigationState } from "../TradeTicket/TradeTicket"
import { TRADE_RECEIPT_LOCATORS } from "./locators"
import { TradeReceiptInformation } from "./TradeReceiptInformation"

export const TradeReceipt = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { symbol, direction } = useParams()
  const { state } = useLocation() as Location<AcceptedOrderNavigationState>
  const response: Order = state?.response

  if (!symbol || !direction || !response) {
    navigate("/mobile/")
    return null
  }

  return (
    <LayoutContainer
      routePattern={RootPath.tradeInstrumentTradeReceipt(
        symbol,
        direction as Direction,
      )}
      data-testid=""
    >
      <Box padding={4}>
        <Subscribe>
          <TradeReceiptInformation symbol={symbol} orderResponse={response} />
        </Subscribe>
      </Box>
      <BottomBar>
        <Button
          component={Link}
          to={RootPath.trade}
          data-testid={TRADE_RECEIPT_LOCATORS.closeButton}
          sx={{
            borderRadius: 2,
            color: "text.primary",
            backgroundColor: "background.tertiary",
            fontSize: 20,
            height: 64,
          }}
        >
          <Typography variant="Display md/Semibold">{t("close")}</Typography>
        </Button>
      </BottomBar>
    </LayoutContainer>
  )
}
