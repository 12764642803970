import { Stack } from "@mui/material"
import { PropsWithChildren, ReactNode } from "react"

import { TopBar, TopBarProps } from "../components/utils"
import { ChildRoute, LayoutContainer } from "./LayoutContainer"

interface Props {
  back?: TopBarProps["back"]
  header: ReactNode
  routePattern: string
  childRoutes?: ChildRoute[]
  "data-testid": string
}

export const SimpleViewLayout = ({
  back,
  header,
  routePattern,
  childRoutes,
  children,
  "data-testid": testId,
}: PropsWithChildren<Props>) => (
  <LayoutContainer
    routePattern={routePattern}
    childRoutes={childRoutes}
    data-testid={testId}
  >
    <Stack padding={4} paddingTop={back ? 4 : 0} gap={6}>
      {back && <TopBar back={back} />}
      <Stack paddingTop={back == null ? 7 : undefined} gap={2}>
        {header}

        {children}
      </Stack>
    </Stack>
  </LayoutContainer>
)
