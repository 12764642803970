import { Components, Theme } from "@mui/material"

export const MuiTabs: Required<Components<Theme>>["MuiTabs"] = {
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      minHeight: "unset",
      backgroundColor: palette.background.secondary,
      "& .MuiTabs-indicator": {
        display: "none",
      },
    }),
  },
}
