import {
  registerWSService$,
  WSEndPoint,
  WSService,
} from "../utils/registerWSService$"

export interface OrderHistory {
  id: string
  clientOrderId: string
  createdAt: string
  updatedAt: string
  submittedAt: string
  filled_at?: string | null
  asset_id?: string
  symbol: string
  asset_class?: string
  notional?: string | null
  qty: string
  filledQty: string
  filled_avg_price?: string | null
  order_class?: string
  orderType: string
  type?: string
  side: string
  time_in_force?: string
  limit_price?: string | null
  stop_price?: string | null
  status: string
  extended_hours?: boolean
  commission?: string
  source?: string
}

export interface OrderHistoryServiceSchema {
  getOrders: WSService<WSEndPoint.getOrders>
}

export const OrderHistoryService: OrderHistoryServiceSchema = {
  getOrders: registerWSService$(WSEndPoint.getOrders),
}
