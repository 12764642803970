import * as icons from "./icons"

export type IconNames = Lowercase<keyof typeof icons>

export const iconMap = Object.fromEntries(
  Object.entries(icons).map(([name, Component]) => [
    name.toLowerCase(),
    Component,
  ]),
)
