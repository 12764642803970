import { Box } from "@mui/material"
import { PropsWithChildren } from "react"
import {
  matchPath,
  PathRouteProps,
  Route,
  Routes,
  useLocation,
} from "react-router-dom"

import { BottomSpacer } from "../components/utils"

export type ChildRoute = Required<Pick<PathRouteProps, "path" | "element">> &
  Omit<PathRouteProps, "path" | "element">

interface Props {
  routePattern: string
  childRoutes?: ChildRoute[]
  "data-testid": string
}

export const LayoutContainer = ({
  routePattern,
  childRoutes,
  children,
  "data-testid": testId,
}: PropsWithChildren<Props>) => {
  const { pathname } = useLocation()

  // To preserve the state (internal state, scroll position) of parent views,
  // we're keeping them rendered, but are hiding them
  const isCurrentView = matchPath(routePattern, pathname) != null

  return (
    <>
      <Box
        width="100vw"
        height="100vh"
        overflow="auto"
        // https://developer.mozilla.org/en-US/docs/Web/CSS/env
        paddingTop="env(safe-area-inset-top)"
        position="absolute"
        data-testid={testId}
        sx={
          isCurrentView
            ? undefined
            : {
                display: "none",
              }
        }
      >
        {children}

        <BottomSpacer />
      </Box>

      {childRoutes != null ? (
        <Routes>
          {childRoutes.map((child) => (
            <Route
              key={child.path}
              path={`${child.path.substring(routePattern.length)}/*`}
              element={child.element}
            />
          ))}
        </Routes>
      ) : null}
    </>
  )
}
