import { forwardRef } from "react"
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom"

export const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props
  // Map MUI "href" -> react-router "to"
  return <RouterLink ref={ref} to={href} {...other} />
})
LinkBehavior.displayName = "LinkBehavior"
