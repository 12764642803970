export interface SelectItem {
  label: string
  value: string
}

export const timeInForceSelections: SelectItem[] = [
  { label: "Day", value: "day" },
  { label: "Good-Til-Canceled", value: "gtc" },
  { label: "Submit On Market Open", value: "opg" },
  { label: "Submit On Market Close", value: "cls" },
  { label: "Immediate-Or-Cancel", value: "ioc" },
  { label: "Fill-Or-Kill", value: "fok" },
]
