import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { ErrorBoundary, INSTRUMENT_PAGE_LOCATORS } from "@/shared/components"

import { InstrumentChart } from "./InstrumentChart"
import { InstrumentDetails } from "./InstrumentDetails"
import { InstrumentHeader } from "./InstrumentHeader"
import { ToggleChartHeaderGroup } from "./ToggleChartHeaderGroup"

export const InstrumentPage = () => {
  const { t } = useTranslation()

  const { symbol } = useParams()
  if (!symbol) {
    throw Error()
  }

  return (
    <Box
      flex="1 1 80%"
      minWidth={800}
      overflow="scroll"
      borderRadius={1}
      bgcolor="background.secondarySubtle"
      data-testid="InstrumentPage"
    >
      <InstrumentHeader
        symbol={symbol}
        data-testid={INSTRUMENT_PAGE_LOCATORS.root}
      />
      <ToggleChartHeaderGroup />
      <ErrorBoundary fallback={<div>{t("errorLoadingInstrumentDetails")}</div>}>
        <InstrumentChart symbol={symbol} />
        <InstrumentDetails symbol={symbol} />
      </ErrorBoundary>
    </Box>
  )
}
