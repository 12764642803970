import "./muiModuleOverrides"

import { createTheme, PaletteOptions } from "@mui/material"
import { TypographyOptions } from "@mui/material/styles/createTypography"

import { components } from "./components"
import { constants } from "./constants"
import { resolveTheme } from "./generated/uiskv2Theme"

const genDarkTheme = resolveTheme({ "1. Color modes": "Dark mode" })
const genLightTheme = resolveTheme({ "1. Color modes": "Light mode" })

const genDarkPalette: PaletteOptions = {
  mode: "dark",
  contrastThreshold: 4.5,
  // Main brand color (lavender?) === #8184f8
  // ~== lavender #e6e6fa (almost bg-brand-primary) .. dark lavender is #734f96,
  primary: {
    main: genDarkTheme.variables["1. Color modes"][
      "Colors/Background/bg-brand-primary"
    ],
    light: "limegreen", // or #76ff03, say
    // === #676ac6 - not ok for A11y contrasted with black
    dark: genDarkTheme.variables["1. Color modes"][
      "Colors/Background/bg-brand-secondary"
    ],
    contrastText:
      genDarkTheme.variables["1. Color modes"]["Colors/Text/text-black"],
  },
  // TODO - sort out below
  secondary: {
    main: "#171717",
    light: "#0c0c0c",
    dark: "#151515",
    contrastText: "#ffffff",
  },
  info: {
    main: "#0284c7",
    light: "#10acfd",
    dark: "#016395",
    contrastText: "#ffffff",
  },
  success: {
    main: "#16a34a",
    light: "#20e167",
    dark: "#107a38",
    contrastText: "#ffffff",
  },
  warning: {
    main: "#ea580c",
    light: "#f57b3b",
    dark: "#b04209",
    contrastText: "#ffffff",
  },
  error: {
    main: "#df4444",
    // TODO Dave to confirm, I replaced below with this custom color after our disc
    // === "#c41230", now #c34646 << 4.5 contrast
    // main: genDarkTheme.variables["1. Color modes"]["Colors/Text/text-error-primary (600)"],
  },
  buy: {
    // === #8e90f9
    main: genDarkTheme.variables["1. Color modes"][
      "Colors/Background/bg-buy-primary"
    ],
    light: "#5585ef",
    // === #676ac6
    dark: genDarkTheme.variables["1. Color modes"][
      "Colors/Background/bg-buy-primary_hover"
    ],
    contrastText: "#000000",
  },
  sell: {
    // === #c01048
    main: genDarkTheme.variables["1. Color modes"][
      "Colors/Background/bg-sell-primary"
    ],
    light: "#e45656",
    // === #e31b54
    dark: genDarkTheme.variables["1. Color modes"][
      "Colors/Background/bg-sell-primary_hover"
    ],
    contrastText: "#ffffff",
  },
  positive: {
    // === #17b26a
    main: genDarkTheme.variables["_Primitives"]["Colors/Data/Positive/500"],
    light: "#08e29e",
    dark: "#04714f",
    contrastText: "#ffffff",
  },
  negative: {
    // === #c34646
    main: genDarkTheme.variables["_Primitives"]["Colors/Data/Negative/200"],
    light: "#e45656",
    dark: "#a71b1b",
    contrastText: "#ffffff",
  },
  neutral: {},
  action: {
    active: "#ffffff8f",
    hover: "#ffffff14",
    selected: "#ffffff29",
    // === #8184f8
    focus:
      genDarkTheme.variables["1. Color modes"][
        "Colors/Effects/Focus rings/focus-ring"
      ],
    // === #737373
    disabled:
      genDarkTheme.variables["1. Color modes"]["Colors/Text/text-disabled"],
    // === #292929
    disabledBackground:
      genDarkTheme.variables["1. Color modes"]["Colors/Background/bg-disabled"],
  },
  background: {
    // MUI -> body.backgroundColor
    default:
      genDarkTheme.variables["1. Color modes"]["Colors/Background/bg-primary"],
    // === #0f0f0f
    primary:
      genDarkTheme.variables["1. Color modes"]["Colors/Background/bg-primary"],
    // === #141414
    primaryAlt:
      genDarkTheme.variables["1. Color modes"][
        "Colors/Background/bg-primary_alt"
      ],
    // === #141414
    secondarySubtle:
      genDarkTheme.variables["1. Color modes"][
        "Colors/Background/bg-secondary_subtle"
      ],
    // === #1f1f1f
    secondary:
      genDarkTheme.variables["1. Color modes"][
        "Colors/Background/bg-secondary"
      ],
    // === #292929
    tertiary:
      genDarkTheme.variables["1. Color modes"]["Colors/Background/bg-tertiary"],
    // === #424242
    quaternary:
      genDarkTheme.variables["1. Color modes"][
        "Colors/Background/bg-quaternary"
      ],
    // === #8184f8
    brandPrimary:
      genDarkTheme.variables["1. Color modes"][
        "Colors/Background/bg-brand-primary"
      ],
  },
  // TODO Dave confirm if we need a distinct background - right now ok to use bg-tertiary
  divider:
    genDarkTheme.variables["1. Color modes"]["Colors/Background/bg-tertiary"],

  text: {
    // === #f7f7f7 MUI -> body.color
    primary:
      genDarkTheme.variables["1. Color modes"][
        "Colors/Text/text-primary (900)"
      ],
    // === #d6d6d6
    secondary:
      genDarkTheme.variables["1. Color modes"][
        "Colors/Text/text-secondary (700)"
      ],
    // === #737373,
    disabled:
      genDarkTheme.variables["1. Color modes"]["Colors/Text/text-disabled"],

    // === #0f0f0f,
    primaryAlt:
      genDarkTheme.variables["1. Color modes"]["Colors/Text/text-primary_alt"],

    // === #737373,
    placeholder:
      genDarkTheme.variables["1. Color modes"]["Colors/Text/text-placeholder"],

    // === #a3a3a3
    tertiary:
      genDarkTheme.variables["1. Color modes"][
        "Colors/Text/text-tertiary (600)"
      ],
    // === #737373
    quaternary:
      genDarkTheme.variables["1. Color modes"][
        "Colors/Text/text-quaternary (500)"
      ],
    // === #8e90f9
    brandPrimary:
      genDarkTheme.variables["1. Color modes"][
        "Colors/Text/text-brand-primary (900)"
      ],
    // === #a3a3a3
    brandTertiary:
      genDarkTheme.variables["1. Color modes"][
        "Colors/Text/text-brand-tertiary (600)"
      ],
    // === #a7a9fa
    quaternaryOnBrand:
      genDarkTheme.variables["1. Color modes"][
        "Colors/Text/text-quaternary_on-brand"
      ],
  },
}

const genLightPalette: PaletteOptions = {
  mode: "light",
  contrastThreshold: 4.5,
  primary: {
    // Light brand color === #e6e6fe (nearly lavender #e6e6fa)
    main: genLightTheme.variables["1. Color modes"][
      "Colors/Background/bg-brand-primary"
    ],
    light: "limegreen", // or #76ff03, say
    // === #d9dafd
    dark: genLightTheme.variables["1. Color modes"][
      "Colors/Background/bg-brand-secondary"
    ],
    contrastText:
      genLightTheme.variables["1. Color modes"]["Colors/Text/text-black"],
  },
  secondary: {
    main: "#161a23",
    light: "#3c4760",
    dark: "#020303",
    contrastText: "#ffffff",
  },
  error: {
    main: "#df4444", // TODO Dave to confirm, I replaced below with this custom color after our disc
    // main: genLightTheme.variables["1. Color modes"][
    //   "Colors/Text/text-error-primary (600)" // === "#c41230" << 4.5 contrast
    // ],
  },
  buy: {
    // === #8e90f9
    main: genLightTheme.variables["1. Color modes"][
      "Colors/Background/bg-buy-primary"
    ],
    light: "#5585ef",
    // === #41427c
    dark: genLightTheme.variables["1. Color modes"][
      "Colors/Background/bg-buy-primary_hover"
    ],
    contrastText: "#000000",
  },
  sell: {
    // === #e31b54
    main: genLightTheme.variables["1. Color modes"][
      "Colors/Background/bg-sell-primary"
    ],
    light: "#e45656",
    // === #c01048
    dark: genLightTheme.variables["1. Color modes"][
      "Colors/Background/bg-sell-primary_hover"
    ],
    contrastText: "#ffffff",
  },
  positive: {
    // === #17b26a
    main: genLightTheme.variables["_Primitives"]["Colors/Data/Positive/500"],
    light: "#08e29e",
    dark: "#04714f",
    contrastText: "#ffffff",
  },
  negative: {
    // === #c34646
    main: genLightTheme.variables["_Primitives"]["Colors/Data/Negative/200"],
    light: "#e45656",
    dark: "#a71b1b",
    contrastText: "#ffffff",
  },
  neutral: {},
  action: {
    active: "#161a238f",
    hover: "#161a230a",
    selected: "#161a2314",
    // === #8184f8
    focus:
      genLightTheme.variables["1. Color modes"][
        "Colors/Effects/Focus rings/focus-ring"
      ],
    // === #737373
    disabled:
      genLightTheme.variables["1. Color modes"]["Colors/Text/text-disabled"],
    // === #f5f5f5
    disabledBackground:
      genLightTheme.variables["1. Color modes"][
        "Colors/Background/bg-disabled"
      ],
  },
  background: {
    // MUI -> body.backgroundColor
    default:
      genLightTheme.variables["1. Color modes"]["Colors/Background/bg-primary"],
    // === #f5f5f5
    primary:
      genLightTheme.variables["1. Color modes"]["Colors/Background/bg-primary"],
    // === #ffffff
    primaryAlt:
      genLightTheme.variables["1. Color modes"][
        "Colors/Background/bg-primary_alt"
      ],
    // === #fcfcfc
    secondarySubtle:
      genLightTheme.variables["1. Color modes"][
        "Colors/Background/bg-secondary_subtle"
      ],
    // === #f7f7f7
    secondary:
      genLightTheme.variables["1. Color modes"][
        "Colors/Background/bg-secondary"
      ],
    // === #f5f5f5
    tertiary:
      genLightTheme.variables["1. Color modes"][
        "Colors/Background/bg-tertiary"
      ],
    // === #e5e5e5
    quaternary:
      genLightTheme.variables["1. Color modes"][
        "Colors/Background/bg-quaternary"
      ],
    // === #e6e6fe
    brandPrimary:
      genLightTheme.variables["1. Color modes"][
        "Colors/Background/bg-brand-primary"
      ],
  },
  // TODO Dave confirm if we need a distinct background - right now ok to use bg-tertiary
  divider:
    genLightTheme.variables["1. Color modes"]["Colors/Background/bg-tertiary"],

  text: {
    // === #141414 MUI -> body.color
    primary:
      genLightTheme.variables["1. Color modes"][
        "Colors/Text/text-primary (900)"
      ],
    // === #424242
    secondary:
      genLightTheme.variables["1. Color modes"][
        "Colors/Text/text-secondary (700)"
      ],
    // === #737373,
    disabled:
      genLightTheme.variables["1. Color modes"]["Colors/Text/text-disabled"],

    // === #fcfcfc,
    primaryAlt:
      genLightTheme.variables["1. Color modes"]["Colors/Text/text-primary_alt"],

    // === #737373,
    placeholder:
      genLightTheme.variables["1. Color modes"]["Colors/Text/text-placeholder"],

    // === #525252,
    tertiary:
      genLightTheme.variables["1. Color modes"][
        "Colors/Text/text-tertiary (600)"
      ],
    // === #737373,
    quaternary:
      genLightTheme.variables["1. Color modes"][
        "Colors/Text/text-quaternary (500)"
      ],
    // === #8184f8,
    brandPrimary:
      genLightTheme.variables["1. Color modes"][
        "Colors/Text/text-brand-primary (900)"
      ],
    // === #676ac6,
    brandTertiary:
      genLightTheme.variables["1. Color modes"][
        "Colors/Text/text-brand-tertiary (600)"
      ],
    // === #4d4f95,
    quaternaryOnBrand:
      genLightTheme.variables["1. Color modes"][
        "Colors/Text/text-quaternary_on-brand"
      ],
  },
}

const darkTheme = createTheme(
  {
    components,
    palette: genDarkPalette,
    typography: genDarkTheme.textStyles as TypographyOptions,
    spacing: 4,
  },
  { constants, ...genDarkTheme },
)

const lightTheme = createTheme(
  {
    components,
    palette: genLightPalette,
    typography: genLightTheme.textStyles as TypographyOptions,
    spacing: 4,
  },
  { constants, ...genLightTheme },
)
export const themes = { light: lightTheme, dark: darkTheme }
