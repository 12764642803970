import { Direction, OrderType } from "../orders"
import {
  createEventBusRequest$,
  EventBusEndPoint,
  EventBusService,
} from "../utils/createEventBusRequest$"

export enum OrderStatus {
  rejected = "rejected",
  accepted = "accepted",
}

type OrderRequest = {
  symbol: string
  qty: string
  side: Direction
  type: OrderType
}

export type StopOrderRequest = OrderRequest & {
  timeInForce: string
  stopPrice: string
  limitPrice?: string
  type: OrderType.StopOrder | OrderType.StopLimitOrder
}

export type LimitOrderRequest = OrderRequest & {
  limitPrice: string
  timeInForce: string
  type: OrderType.LimitOrder
}

export type MarketOrderRequest = OrderRequest & {
  type: OrderType.MarketOrder
}

interface OrderResponse {
  symbol: string
  qty: string
  side: string
  commission: string
  clientOrderId: string
  status: string
  createdAt: string
}

export interface Order {
  type: OrderStatus
  order: OrderResponse
  error?: {
    type: string
    message: string
    msg?: {
      code: number
      message: string
    }
  }
}

export interface OrderServiceSchema {
  placeOrder$: EventBusService<EventBusEndPoint.placeOrder>
}

export const OrderService: OrderServiceSchema = {
  placeOrder$: createEventBusRequest$(EventBusEndPoint.placeOrder),
}
