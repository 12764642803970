import { Navigate, Route, Routes } from "react-router-dom"

import { Login } from "./desktop/components/Login/Login"
import { DesktopRoutes } from "./desktop/DesktopRoutes"
import { Login as MobileLogin } from "./mobile/components/login/Login"
import { MobileRoutes } from "./mobile/MobileRoutes"

export const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/desktop" replace />} />
    <Route key="/desktop" path="/desktop/*" element={<DesktopRoutes />} />
    <Route key="/mobile" path="/mobile/*" element={<MobileRoutes />} />
    <Route key="/desktop/login" path="/desktop/login/*" element={<Login />} />
    <Route
      key="/mobile/login"
      path="/mobile/login/*"
      element={<MobileLogin />}
    />
  </Routes>
)
