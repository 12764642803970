import { Components, Theme } from "@mui/material"

export const MuiFormControlLabel: Required<
  Components<Theme>
>["MuiFormControlLabel"] = {
  defaultProps: {
    componentsProps: {
      typography: {
        variant: "Text md/Regular",
      },
    },
  },
  styleOverrides: {
    root: ({ theme: { palette, typography } }) => ({
      ...typography["Text md/Regular"],
      color: palette.text.secondary,
    }),
  },
}
