import { Subscribe } from "@react-rxjs/core"
import { ComponentType, ReactNode } from "react"
import { Observable } from "rxjs"

export const withSubscribe = <T,>(
  Component: ComponentType<T>,
  options?: { fallback?: ReactNode; source$?: Observable<unknown> },
) => {
  const WithSubscribe = (props: T) => (
    <Subscribe fallback={options?.fallback ?? null} source$={options?.source$}>
      <Component {...(props as T & JSX.IntrinsicAttributes)} />
    </Subscribe>
  )

  WithSubscribe.displayName = `WithSubscribe(${getDisplayName(Component)})`

  return WithSubscribe
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getDisplayName(Component: ComponentType<any>) {
  return Component.displayName || Component.name || "Component"
}
