import { bind } from "@react-rxjs/core"
import { distinctUntilChanged } from "rxjs"

import { WatchList, WatchListService } from "../TradingGateway"
import { WSEndPoint } from "../utils/registerWSService$"
import { scanPagingEvents } from "../utils/scanPagingEvents"

export const [useWatchList, watchList$] = bind<WatchList[]>(
  WatchListService.getWatchList().pipe(
    scanPagingEvents<WatchList>(WSEndPoint.getWatchList),
    distinctUntilChanged(),
  ),
)
