const formatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  signDisplay: "auto",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const formatPercentage = (amount: number | string) => {
  if (typeof amount === "string") {
    amount = parseFloat(amount)
  }

  return formatter.format(amount)
}

const formatterSigned = new Intl.NumberFormat("en-US", {
  style: "percent",
  signDisplay: "exceptZero",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const formatPercentageSigned = (amount: number | string) => {
  if (typeof amount === "string") {
    amount = parseFloat(amount)
  }

  return formatterSigned.format(amount)
}
