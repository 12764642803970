import { Skeleton } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useTranslation } from "react-i18next"

import {
  setLimitPrice,
  setLimitTimeInForce,
  setQty,
  useLimitPriceValue,
  useQtyValue,
  useTimeInForceValue,
} from "@/shared/services/orders"

import { OrderInput, OrderSelect } from "../OrderInputs"
import { timeInForceSelections } from "./timeInForceSelections"

export const LimitOrderForm = () => {
  const { t } = useTranslation()

  const limitPriceValue = useLimitPriceValue()
  const limitQtyValue = useQtyValue()
  const limitTimeInForceValue = useTimeInForceValue()

  return (
    <Subscribe fallback={<Skeleton />}>
      <OrderInput
        onChange={(value) => setQty(value)}
        value={limitQtyValue}
        data-testid="limitQty"
        name="limitQty"
        label={t("quantity")}
      />
      <OrderInput
        onChange={(value) => setLimitPrice(value)}
        value={limitPriceValue}
        data-testid="limitPrice"
        name="limitPrice"
        label={t("limitPrice")}
      />
      <OrderSelect
        options={timeInForceSelections}
        onChange={(e) => setLimitTimeInForce(e.target.value as string)}
        value={limitTimeInForceValue}
        data-testid="timeinForce"
        name="timeInForce"
        label={t("timeInForce")}
      />
    </Subscribe>
  )
}
