import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { t } from "i18next"
import { PropsWithChildren, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { helloLogin } from "@/shared/services/login/service"
import {
  ConnectionState,
  setEmailLogged,
  setErrorInLogin,
  setIsEventBusTimeout,
} from "@/shared/services/login/signal"
import {
  useEmailLogged,
  useEventBusConnectionState,
  useIsErrorInLogin,
  useIsEventBusTimeout,
} from "@/shared/services/login/state"
import { ErrorTypes } from "@/shared/services/utils/errorTypes"

import { LOGIN_LOCATORS } from "./locators"

interface Props {
  buttonWrapper?: (props: PropsWithChildren) => JSX.Element
}

const loginErrorMsg = (errorType: ErrorTypes) => {
  switch (errorType) {
    case ErrorTypes.INACTIVE_ACCOUNT:
      return t("inactiveUser")
    case ErrorTypes.SERVER_ERROR:
      return t("serverError")
    case ErrorTypes.UNKNOWN_ACCOUNT_KEY:
      return t("unknownAccountKey")
    default:
      return t("defaultError")
  }
}

export const LoginForm = ({ buttonWrapper }: Props) => {
  const navigate = useNavigate()

  const isErrorLogin = useIsErrorInLogin()
  const isEventBusTimeout = useIsEventBusTimeout()
  const eventBusConnectionState = useEventBusConnectionState()
  const emailLogged = useEmailLogged()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  const signIn = () => {
    setErrorInLogin(null)
    setLoading(true)

    if (import.meta.env.VITE_MOCKS && !emailLogged) {
      setEmailLogged("mock")
      setIsEventBusTimeout(false)
      localStorage.setItem("email", email)
      return
    }

    helloLogin(email)
  }

  useEffect(() => {
    setLoading(false)
  }, [isEventBusTimeout, eventBusConnectionState, isErrorLogin, email])

  useEffect(() => {
    if (!isErrorLogin && emailLogged) {
      navigate("/")
    }
  }, [emailLogged, isErrorLogin, navigate])

  const ButtonWrapper = buttonWrapper ?? Stack

  // TODO Dave - overrides below, pending Login page design

  // overrides general tertiary bg
  const InputProps = {
    sx: {
      backgroundColor: "transparent",
    },
  }

  // overrides general typography of 12px
  const inputProps = {
    sx: {
      fontSize: 20,
    },
  }

  return (
    <FormControl
      error={
        isEventBusTimeout ||
        eventBusConnectionState === ConnectionState.DISCONNECTED
      }
      sx={{ maxWidth: "350px", width: "350px", gap: 4 }}
    >
      <TextField
        label={t("email")}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        data-testid={LOGIN_LOCATORS.emailInput}
        error={Boolean(isErrorLogin)}
        helperText={isErrorLogin && loginErrorMsg(isErrorLogin)}
        InputProps={InputProps}
        inputProps={inputProps}
      />

      <TextField
        label={t("password")}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        data-testid={LOGIN_LOCATORS.passwordInput}
        InputProps={InputProps}
        inputProps={inputProps}
      />

      <FormControlLabel control={<Checkbox />} label={t("rememberMe")} />

      <Typography variant="Text md/Regular">
        <Link href="#" color="text.quaternaryOnBrand">
          {t("signup")}
        </Link>
        &nbsp;&nbsp;/&nbsp;&nbsp;
        <Link href="#" color="text.quaternaryOnBrand">
          {t("forgottenPassword")}
        </Link>
      </Typography>

      <ButtonWrapper sx={{ paddingBlockStart: 1 }}>
        <Button
          data-testid={LOGIN_LOCATORS.loginButton}
          onClick={signIn}
          variant="contained"
          sx={{ width: "100%" }}
          disabled={
            loading || eventBusConnectionState !== ConnectionState.CONNECTED
          }
        >
          {t("login")}
          {loading && <CircularProgress size={24} />}
        </Button>
      </ButtonWrapper>

      <FormHelperText sx={{ color: "error.main" }}>
        {isEventBusTimeout
          ? t("eventBusTimeout")
          : eventBusConnectionState === ConnectionState.DISCONNECTED &&
            t("eventBusNotReachable")}
      </FormHelperText>
    </FormControl>
  )
}
