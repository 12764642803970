import { bind } from "@react-rxjs/core"
import { distinctUntilChanged, map } from "rxjs"

import {
  Instrument,
  InstrumentsService,
} from "@/shared/services/TradingGateway"

import { WSEndPoint } from "../utils/registerWSService$"
import { scanPagingEvents } from "../utils/scanPagingEvents"

export const featuredInstrumentSymbols = [
  "AAPL",
  "AMZN",
  "F",
  "GE",
  "META",
  "MSFT",
  "NFLX",
  "NVDA",
  "SPY",
  "TSLA",
]

export const [useInstruments, instruments$] = bind<Instrument[]>(
  InstrumentsService.getInstruments().pipe(
    scanPagingEvents<Instrument>(WSEndPoint.getInstruments),
    distinctUntilChanged(),
  ),
)

export const [useInstrument, useInstrument$] = bind((symbol: string) =>
  instruments$.pipe(
    map((instruments) =>
      instruments.find((instrument) => instrument.symbol === symbol),
    ),
  ),
)
