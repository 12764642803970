import { Skeleton } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { t } from "i18next"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { InstrumentHeader } from "@/mobile/components/instrumentPage/InstrumentHeader"
import { SimpleViewLayout } from "@/mobile/layout"
import { RootPath } from "@/mobile/paths"
import {
  BalanceDetails,
  IndicativeCost,
  OrderErrorAlert,
  OrderTabs,
  SubmitOrderButton,
} from "@/shared/components"
import { DetailField } from "@/shared/components/order/DetailField"
import { Direction, useResponse } from "@/shared/services/orders"
import { Order, OrderStatus } from "@/shared/services/TradingGateway"

import { BottomBar } from "../../utils"
import { TRADE_TICKET_LOCATORS } from "./locators"

export type AcceptedOrderNavigationState = {
  response: Order
}
export const TradeTicket = () => {
  const { symbol = "", direction } = useParams()
  const { isLoading, response } = useResponse()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading && response) {
      if (response.type === OrderStatus.accepted) {
        const acceptedOrderState: AcceptedOrderNavigationState = { response }
        navigate(
          RootPath.tradeInstrumentTradeReceipt(symbol, direction as Direction),
          { state: acceptedOrderState },
        )
      }
    }
  }, [isLoading, response, direction, symbol, navigate])

  return (
    <SimpleViewLayout
      back={{ to: RootPath.tradeInstrument(symbol) }}
      header={
        <InstrumentHeader
          symbol={symbol}
          data-testid={TRADE_TICKET_LOCATORS.root}
        />
      }
      routePattern={RootPath.tradeInstrumentTrade.routePattern}
      data-testid=""
    >
      <OrderTabs />
      <Subscribe fallback={<Skeleton />}>
        <DetailField
          label={t("orderIndicativeCost")}
          testId="orderIndicativeCost"
        >
          <IndicativeCost symbol={symbol} />
        </DetailField>
      </Subscribe>
      <BalanceDetails />

      <BottomBar>
        <SubmitOrderButton
          symbol={symbol}
          direction={direction as Direction}
          sx={{ height: 64, fontSize: 20, borderWidth: 2 }}
        />
      </BottomBar>

      <OrderErrorAlert />
    </SimpleViewLayout>
  )
}
