import { Theme } from "@mui/material"

import { ThemeMode } from "../theme/ThemeContext"
import { ChartIQThemeSettings } from "./types"

export const createChartTheme = ({ palette }: Theme): ChartIQThemeSettings => ({
  chart: {
    Background: {
      color: palette.background.primary,
    },
    "Grid Lines": {
      color:
        palette.mode === ThemeMode.DARK
          ? palette.background.secondary
          : palette.background.secondarySubtle,
    },
    "Grid Dividers": {
      color: "transparent",
    },
    "Axis Text": {
      color: palette.text.tertiary,
    },
  },
  chartTypes: {
    "Candle/Bar": {
      up: {
        color: palette.positive!.main,
        wick: palette.positive!.main,
        border: "transparent",
      },
      down: {
        color: palette.negative!.main,
        wick: palette.negative!.main,
        border: "transparent",
      },
      even: {
        color: palette.positive!.main,
        wick: palette.positive!.main,
        border: "transparent",
      },
    },
    Line: {
      color: palette.text.primary,
    },
    Mountain: {
      color: palette.text.primary,
      basecolor: palette.secondary.main,
    },
  },
})
