interface Constants {
  buttonHeight: number
  desktopHeaderHeight: number
  semiBoldFontWeight: number
}

// This needs to be defined, as ThemeOptions['components'] relies on it
declare module "@mui/system" {
  interface Theme {
    constants: Constants
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    constants: Constants
  }

  interface ThemeOptions {
    constants?: Constants
  }
}

export const constants: Constants = {
  buttonHeight: 48,
  desktopHeaderHeight: 55,
  semiBoldFontWeight: 500,
}
