import { useTheme } from "@mui/material"
import { CIQ } from "chartiq"
import { useEffect, useRef } from "react"

import { createChartTheme } from "./createChartTheme"
import {
  clearChartEngine,
  setChartEngine,
  useChartEngine,
  useCurrentSymbol,
} from "./state"

export const useRegisterChartEngine = () => {
  const container = useRef<HTMLDivElement>(null)
  const symbol = useCurrentSymbol()

  useEffect(() => {
    const chartContainer = container.current
    if (!chartContainer) {
      throw Error()
    }

    const chartEngine = new CIQ.ChartEngine({
      container: chartContainer,
      layout: {
        chartType: "candle",
      },
      controls: {
        chartControls: null,
        home: null,
      },
    })

    chartEngine.chart.allowScrollFuture = false
    // Sets the dp of the current price label on the y-Axis to 2
    chartEngine.chart.yAxis.priceFormatter = (
      _chartEngine: CIQ.ChartEngine,
      _panel: CIQ.ChartEngine.Panel,
      price: number,
    ) => {
      return price.toFixed(2)
    }

    setChartEngine(chartEngine)

    return () => {
      clearChartEngine()
    }
  }, [symbol])

  const chartEngine = useChartEngine()
  const theme = useTheme()

  useEffect(() => {
    if (!chartEngine) {
      return
    }

    const helper = new CIQ.ThemeHelper({ stx: chartEngine })
    helper.settings = createChartTheme(theme)
    helper.update()
  }, [theme, chartEngine])

  return container
}
