import { bind } from "@react-rxjs/core"
import { shareReplay } from "rxjs"

import { AccountSummaryService } from "../TradingGateway"

export const [useAccountSummary] = bind(
  AccountSummaryService.getAccountSummary().pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
  ),
)
