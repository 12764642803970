import { createContext, Dispatch, SetStateAction } from "react"

export enum ThemeMode {
  DARK = "dark",
  LIGHT = "light",
}

interface ContextValue {
  themeMode: ThemeMode
  setThemeMode: Dispatch<SetStateAction<ThemeMode>>
}

export const ThemeContext = createContext<ContextValue>({
  themeMode: ThemeMode.DARK,
  // eslint-disable-next-line no-console
  setThemeMode: () => console.warn("Missing StorageThemeProvider"),
})
