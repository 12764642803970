import { ThemeProvider as MuiThemeProvider } from "@mui/material"
import { PropsWithChildren, useEffect, useState } from "react"

import { themes } from "@/shared/theme"

import { ThemeContext, ThemeMode } from "./ThemeContext"

const STORAGE_KEY = "themeMode"

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [themeMode, setThemeMode] = useState<ThemeMode>(
    (localStorage.getItem(STORAGE_KEY) ?? ThemeMode.DARK) as ThemeMode,
  )

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, themeMode)
  }, [themeMode])

  return (
    <ThemeContext.Provider value={{ themeMode, setThemeMode }}>
      <MuiThemeProvider theme={themes[themeMode]}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  )
}
