import { Box, Skeleton } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { t } from "i18next"

import {
  BalanceDetails,
  IndicativeCost,
  OrderErrorAlert,
  OrderTabs,
  SubmitOrderButton,
  TradeButton,
} from "@/shared/components"
import { DetailField } from "@/shared/components/order/DetailField"
import {
  Direction,
  toggleDirection,
  useTradeDirection,
} from "@/shared/services/orders"

interface Props {
  symbol: string
}

export const OrderForm = ({ symbol }: Props) => {
  const tradeDirection = useTradeDirection()

  return (
    <>
      <Box display="flex" gap={2} paddingTop={2}>
        <Subscribe fallback={<Skeleton />}>
          <TradeButton
            disableElevation
            direction={Direction.Buy}
            sx={{
              height: 36,
            }}
            onClick={() => toggleDirection(Direction.Buy)}
          />
          <TradeButton
            disableElevation
            direction={Direction.Sell}
            sx={{
              height: 36,
            }}
            onClick={() => toggleDirection(Direction.Sell)}
          />
        </Subscribe>
      </Box>

      <OrderTabs />

      <Subscribe fallback={<Skeleton />}>
        <DetailField
          label={t("orderIndicativeCost")}
          testId="orderIndicativeCost"
        >
          <IndicativeCost symbol={symbol} />
        </DetailField>
      </Subscribe>
      <SubmitOrderButton
        disableElevation
        direction={tradeDirection}
        symbol={symbol}
      />
      <BalanceDetails />
      <OrderErrorAlert />
    </>
  )
}
