import { bind } from "@react-rxjs/core"
import { createSignal } from "@react-rxjs/utils"
import { debounceTime, map, of, switchMap } from "rxjs"

import { instruments$ } from "@/shared/services/instruments"

const [_searchValue$, setSearchValue] = createSignal<string>()

const [useSearchValue, searchValue$] = bind(_searchValue$, "")

const match = (value: string, match: string) =>
  value.toLowerCase().startsWith(match.toLowerCase())

const searchResult$ = searchValue$.pipe(
  debounceTime(500),
  switchMap((searchValue) =>
    searchValue
      ? instruments$.pipe(
          map((instruments) =>
            instruments
              .filter(
                (instrument) =>
                  match(instrument.name, searchValue) ||
                  match(instrument.symbol, searchValue),
              )
              //TODO: Remove limit after virtualisation
              .slice(0, 50),
          ),
        )
      : of([]),
  ),
)

const [useSearchResult] = bind(searchResult$, [])

export { setSearchValue, useSearchResult, useSearchValue }
