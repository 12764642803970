import { Components, Theme } from "@mui/material"

export const MuiToggleButton: Required<Components<Theme>>["MuiToggleButton"] = {
  styleOverrides: {
    root: ({ theme: { palette, shadows, spacing, typography } }) => ({
      ...typography["Text md/Medium"],
      textTransform: "none",
      border: "none",
      color: palette.text.tertiary,
      padding: spacing(0, 2),
      "&.MuiToggleButtonGroup-firstButton, &.MuiToggleButtonGroup-middleButton, &.MuiToggleButtonGroup-lastButton":
        {
          borderRadius: "30px",
        },
      "&.Mui-selected, &.Mui-selected:hover": {
        color: palette.text.brandPrimary,
        backgroundColor: palette.background.tertiary,
        boxShadow: shadows[1],
      },
    }),
  },
}
