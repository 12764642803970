import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material"

import { setTimeframe, useTimeframe } from "./state"
import { timeframeConfig } from "./timeframe"
import { Timeframe } from "./types"

export const TimeframeSelector = () => {
  const currentTimeframe = useTimeframe()

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    timeframe: Timeframe | null,
  ) => {
    if (timeframe) {
      setTimeframe(timeframe)
    }
  }

  return (
    <Stack alignItems="center">
      <ToggleButtonGroup
        value={currentTimeframe}
        exclusive
        onChange={handleChange}
        size="small"
      >
        {Object.keys(timeframeConfig).map((timeframe) => (
          <ToggleButton
            key={timeframe}
            value={timeframe}
            size="medium"
            data-testid={timeframe}
          >
            {timeframe}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  )
}
