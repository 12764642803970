import { Components, Theme } from "@mui/material"
import type {} from "@mui/x-data-grid/themeAugmentation"

export const MuiDataGrid: Required<Components<Theme>>["MuiDataGrid"] = {
  defaultProps: {
    rowHeight: 60,
    columnHeaderHeight: 30,
    disableColumnMenu: true,
    hideFooterPagination: true,
    disableRowSelectionOnClick: true,
  },
  styleOverrides: {
    root: ({ theme: { palette, typography } }) => ({
      ...typography["Text md/Regular"],
      border: "none",
      backgroundColor: palette.background.primary,
    }),
    columnHeaders: {
      borderBottom: "none",
    },
    columnHeader: ({ theme: { palette } }) => ({
      color: palette.text.quaternary,
      "&:focus": {
        outline: "none",
      },
    }),
    columnSeparator: {
      display: "none",
    },
    row: {
      "&:last-child .MuiDataGrid-cell": {
        border: "none",
      },
    },
    cell: ({ theme: { palette } }) => ({
      border: "none",
      "&.none": {
        color: palette.text.secondary,
      },
      "&.positive": {
        color: palette.positive!.main,
      },
      "&.negative": {
        color: palette.negative!.main,
      },
      "&:focus, &:focus-within": {
        outline: "none",
      },
      "& .MuiDataGrid-actionsCell button": {
        border: `1px solid ${palette.text.secondary}`,
      },
    }),
    footerContainer: {
      display: "none",
    },
  },
}
