const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "narrowSymbol",
})

export const formatCurrency = (amount: number | string) => {
  if (typeof amount === "string") {
    amount = parseFloat(amount)
  }

  return currencyFormatter.format(amount)
}

const currencyFormatterAbbreviated = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "narrowSymbol",
  notation: "compact",
})

export const formatCurrencyAbbreviated = (amount: number | string) => {
  if (typeof amount === "string") {
    amount = parseFloat(amount)
  }

  return currencyFormatterAbbreviated.format(amount)
}

const profitAndLossFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "narrowSymbol",
  signDisplay: "always",
})

export const formatProfitAndLoss = (amount: number | string) => {
  if (typeof amount === "string") {
    amount = parseFloat(amount)
  }

  return profitAndLossFormatter.format(amount)
}
