import { eventBus, serviceStreams$ } from "../connection"
import { ErrorTypes } from "../utils/errorTypes"
import { registerInstrumentService } from "../utils/registerWSService$"
import { setEmailLogged, setErrorInLogin, setIsEventBusTimeout } from "./signal"

type LoginError = {
  type: ErrorTypes
  message: string
}

type ErrorMessage = {
  failureCode: number
  failureType: string
  message: string
}

type SuccessMessage = {
  body: Record<string, { streaming: string; cmd: string }>
}

function handleLoginSuccess(email: string, message: SuccessMessage) {
  setEmailLogged(email)
  setErrorInLogin(null)
  setIsEventBusTimeout(false)

  localStorage.setItem("email", email)
  //TODO: Remove when instruments implements normal service
  registerInstrumentService(message.body)

  serviceStreams$.next(message.body)
}

function handleLoginError(error: ErrorMessage) {
  if (error.failureCode === -1) {
    setIsEventBusTimeout(true)
  } else {
    const errorMsg = JSON.parse(error.message) as LoginError
    setErrorInLogin(errorMsg.type)
  }
}

export function helloLogin(email: string) {
  const accountKey = email || (import.meta.env.VITE_LOGIN_EMAIL as string)

  eventBus.send(
    "hello",
    {
      accountKey,
    },
    (error: ErrorMessage | null, message: SuccessMessage) => {
      if (error) {
        handleLoginError(error)
      } else {
        handleLoginSuccess(accountKey, message)
      }
    },
  )
}
