import {
  QUANTITY_DISPLAY_MAX_DECIMAL_PLACES,
  QUANTITY_INPUT_MAX_DECIMAL_PLACES,
} from "./constants"

const floatFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: QUANTITY_DISPLAY_MAX_DECIMAL_PLACES,
})

export const formatFloat = (amount: number | string) => {
  if (typeof amount === "string") {
    amount = parseFloat(amount)
  }

  return floatFormatter.format(amount)
}

const integerFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const formatInteger = (amount: number | string) => {
  if (typeof amount === "string") {
    amount = parseFloat(amount)
  }

  return integerFormatter.format(amount)
}

export const formatOrderAmount = (number: number): string => {
  const fixedNumber = number.toFixed(QUANTITY_INPUT_MAX_DECIMAL_PLACES)
  const floatNumber = parseFloat(fixedNumber)
  return floatNumber.toString()
}
