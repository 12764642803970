import { Components, Theme } from "@mui/material"

export const MuiTab: Required<Components<Theme>>["MuiTab"] = {
  styleOverrides: {
    root: ({ theme: { palette, spacing, typography } }) => ({
      ...typography["Text md/Semibold"],
      padding: spacing(1, 4),
      minHeight: "unset",
      color: palette.text.quaternary,
      "&.Mui-selected, &.Mui-selected:hover": {
        color: palette.text.quaternaryOnBrand,
        backgroundColor: palette.background.secondarySubtle,
      },
    }),
  },
}
