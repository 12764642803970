import { DataGrid } from "@mui/x-data-grid"
import { useEffect, useState } from "react"

import {
  ConfirmDialog,
  SnackbarMessage,
  SnackbarMessageType,
} from "@/shared/components"
import { useDialog } from "@/shared/services/dialog/dialog.service"
import { useClosePositionResponse } from "@/shared/services/orders"
import { usePositions } from "@/shared/services/positions"
import { Position } from "@/shared/services/TradingGateway"

import { positionsGridColumns } from "./PositionsGridColumns"

export const PositionsGrid = () => {
  const [shouldShowError, showError] = useState(false)
  const positions = usePositions()
  const dialog = useDialog()
  const response = useClosePositionResponse()

  useEffect(() => {
    if (response?.error?.message) {
      showError(true)
    }
  }, [response?.error?.message])

  const handleClose = () => showError(false)

  return (
    <>
      <ConfirmDialog {...dialog} />

      <SnackbarMessage
        type={SnackbarMessageType.ERROR}
        isOpen={shouldShowError}
        onClose={handleClose}
        message={response?.error?.message ?? ""}
      />

      <DataGrid
        sx={{ mx: -4, px: 4, pt: 2.5 }}
        rows={positions}
        columns={positionsGridColumns}
        getRowId={(position: Position) => position.symbol}
      />
    </>
  )
}
