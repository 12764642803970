import { Components, Theme } from "@mui/material"

export const MuiTextField: Required<Components<Theme>>["MuiTextField"] = {
  defaultProps: {
    variant: "standard",
    InputLabelProps: {
      shrink: true,
    },
  },
  styleOverrides: {
    root: ({ theme: { spacing, typography } }) => ({
      ".MuiFormLabel-root": {
        transform: "translate(0, -8px)",
        ...typography["Text md/Regular"],
      },
      ".MuiSelect-nativeInput": {
        ...typography["Text md/Regular"],
      },
      ".MuiSelect-select": {
        padding: spacing(0, 2),
      },
    }),
  },
}
