import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid"
import { t } from "i18next"
import upperFirst from "lodash/upperFirst"

import { SymbolCell } from "@/shared/components"
import { OrderHistory } from "@/shared/services/TradingGateway"
import { formatFloat } from "@/shared/utils"

import { ORDER_HISTORY_PAGE_LOCATORS } from "./locators"

const floatFormatter = (params: GridValueFormatterParams<number>) =>
  params.value == null ? "" : formatFloat(params.value)

const orderTypeTranslations: Record<string, string> = {
  market: t("orderTypeOptions.market"),
  limit: t("orderTypeOptions.limit"),
  stop: t("orderTypeOptions.stop"),
  stop_limit: t("orderTypeOptions.stop_limit"),
  trailing_stop: t("orderTypeOptions.trailing_stop"),
}

const orderStatusTranslations: Record<string, string> = {
  new: t("orderStatus.new"),
  partially_filled: t("orderStatus.partially_filled"),
  filled: t("orderStatus.filled"),
  done_for_day: t("orderStatus.done_for_day"),
  canceled: t("orderStatus.canceled"),
  expired: t("orderStatus.expired"),
  replaced: t("orderStatus.replaced"),
  pending_cancel: t("orderStatus.pending_cancel"),
  pending_replace: t("orderStatus.pending_replace"),
}

export const orderHistoryGridColumns: GridColDef[] = [
  {
    field: "symbol",
    headerName: t("symbol"),
    width: 200,
    sortable: false,
    renderCell: (params: GridRenderCellParams<OrderHistory, string>) =>
      params.value ? (
        <SymbolCell
          symbol={params.value}
          testidPrefix={`${ORDER_HISTORY_PAGE_LOCATORS.grid}.row.${params.value}`}
        />
      ) : null,
  },
  {
    field: "orderType",
    headerName: t("orderType"),
    sortable: false,
    type: "text",
    width: 150,
    valueFormatter: (params: GridValueFormatterParams<string>) => {
      const value = orderTypeTranslations?.[params?.value] ?? params.value
      return upperFirst(value)
    },
  },
  {
    field: "status",
    headerName: t("status"),
    sortable: false,
    width: 150,
    valueFormatter: (params: GridValueFormatterParams<string>) => {
      const value =
        orderStatusTranslations?.[params?.value ?? ""] ?? params.value
      return upperFirst(value)
    },
  },
  {
    field: "qty",
    headerName: t("orderQty"),
    type: "number",
    width: 150,
    sortable: false,
    valueFormatter: floatFormatter,
  },
  {
    field: "filledQty",
    headerName: t("filledQty"),
    type: "number",
    width: 150,
    sortable: false,
    valueFormatter: floatFormatter,
  },
]
