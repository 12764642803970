import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import { CurrentPriceValue } from "@/shared/components"
import { InstrumentTitle } from "@/shared/components/instruments/InstrumentTitle"
import { usePriceAndChange } from "@/shared/services/instruments"
import {
  formatCurrency,
  formatInteger,
  formatPercentageSigned,
} from "@/shared/utils"

import { InfoField } from "../../../shared/components/InfoField"
import { DailyBarValue } from "./DailyBarValue"

interface InstrumentHeaderProps {
  symbol: string
  "data-testid": string
}

export const InstrumentHeader = ({
  symbol,
  "data-testid": testId,
}: InstrumentHeaderProps) => {
  const { t } = useTranslation()

  return (
    <Stack flexDirection="row" alignItems="center" gap={4} padding={4}>
      <InstrumentTitle symbol={symbol} logoVariant="lg" testId={testId} />
      <Stack direction="row" gap={6}>
        <InfoField label={t("lastPrice")} testId="lastPrice" minWidth={55}>
          <CurrentPriceValue symbol={symbol} />
        </InfoField>
        <InfoField
          label={t("24hPriceChange")}
          testId="24hPriceChange"
          minWidth={110}
        >
          <Change symbol={symbol} />
        </InfoField>
        <InfoField label={t("24hHigh")} testId="24hHigh" minWidth={55}>
          <DailyBarValue symbol={symbol} field="h" formatter={formatCurrency} />
        </InfoField>
        <InfoField label={t("24hLow")} testId="24hLow" minWidth={55}>
          <DailyBarValue symbol={symbol} field="l" formatter={formatCurrency} />
        </InfoField>
        <InfoField label={t("24hVolume")} testId="24hVolume">
          <DailyBarValue symbol={symbol} field="v" formatter={formatInteger} />
        </InfoField>
      </Stack>
    </Stack>
  )
}

const Change = ({ symbol }: { symbol: string }) => {
  const { change, changeAmount } = usePriceAndChange(symbol)
  return (
    <Typography
      variant="inherit"
      component="span"
      color={changeAmount < 0 ? "negative.main" : "positive.main"}
    >
      {formatCurrency(changeAmount)} ({formatPercentageSigned(change)})
    </Typography>
  )
}
