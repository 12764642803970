import { Components, Theme } from "@mui/material"

import { MuiButton } from "./MuiButton"
import { MuiButtonBase } from "./MuiButtonBase"
import { MuiCssBaseline } from "./MuiCssBaseline"
import { MuiDataGrid } from "./MuiDataGrid"
import { MuiFormControlLabel } from "./MuiFormControlLabel"
import { MuiFormHelperText } from "./MuiFormHelperText"
import { MuiIconButton } from "./MuiIconButton"
import { MuiInputBase } from "./MuiInputBase"
import { MuiLinearProgress } from "./MuiLinearProgress"
import { MuiLink } from "./MuiLink"
import { MuiTab } from "./MuiTab"
import { MuiTableCell } from "./MuiTableCell"
import { MuiTableRow } from "./MuiTableRow"
import { MuiTabs } from "./MuiTabs"
import { MuiTextField } from "./MuiTextField"
import { MuiToggleButton } from "./MuiToggleButton"
import { MuiToggleButtonGroup } from "./MuiToggleButtonGroup"
import { MuiTypography } from "./MuiTypography"

export const components: Components<Theme> = {
  MuiButton,
  MuiButtonBase,
  MuiCssBaseline,
  MuiDataGrid,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiIconButton,
  MuiInputBase,
  MuiLinearProgress,
  MuiLink,
  MuiTab,
  MuiTableCell,
  MuiTableRow,
  MuiTabs,
  MuiTextField,
  MuiToggleButton,
  MuiToggleButtonGroup,
  MuiTypography,
}
