import { catchError, map, Observable } from "rxjs"
import { ajax, AjaxError } from "rxjs/ajax"

import { Timeframe } from "@/shared/components"
import { constructQuery } from "@/shared/utils"

import type { RawHistoricalBar } from "../TradingGateway"

export enum RestEndPoint {
  getHistoricalBar = "stocks/{symbol}/bars?timeframe={timeframe}&endDate={endDate}",
}

type Request = {
  [RestEndPoint.getHistoricalBar]: {
    symbol: string
    timeframe: Timeframe
    endDate?: string
  }
}

type Response = {
  [RestEndPoint.getHistoricalBar]: RawHistoricalBar[]
}

export type RestService<E extends RestEndPoint> = Request[E] extends undefined
  ? () => Observable<Response[E]>
  : (args: Request[E]) => Observable<Response[E]>

export function createRESTService$<E extends RestEndPoint>(
  endpoint: E,
): RestService<E> {
  return (args?: Request[E]) =>
    ajax<Response[E]>(`/api/${constructQuery(endpoint, args)}`).pipe(
      map((r) => r.response),
      catchError((error: AjaxError) => {
        console.error("error: ", error)
        throw new Error(
          ` got ${error.message} from address ${error.request.url}`,
        )
      }),
    )
}
