import {
  Skeleton,
  Stack,
  Typography,
  TypographyPropsVariantOverrides,
} from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { PropsWithChildren } from "react"

interface InfoFieldProps {
  label: string
  testId: string
  minWidth?: number
  direction?: "row" | "column"
  labelVariant?: keyof TypographyPropsVariantOverrides
  valueVariant?: keyof TypographyPropsVariantOverrides
}

export const InfoField = ({
  label,
  children,
  testId,
  minWidth = 0,
  labelVariant = "Text md/Regular",
  valueVariant = "Text lg/Regular",
  direction = "column",
}: PropsWithChildren<InfoFieldProps>) => (
  <Stack
    direction={direction}
    gap={direction === "column" ? 1 : 2}
    minWidth={minWidth}
  >
    <Typography
      variant={labelVariant}
      component="h3"
      sx={{ lineHeight: "normal" }}
      color="text.tertiary"
    >
      {label}
    </Typography>

    <Typography
      variant={valueVariant}
      sx={{ lineHeight: "normal" }}
      data-testid={testId}
    >
      <Subscribe fallback={<Skeleton width="50px" />}>{children}</Subscribe>
    </Typography>
  </Stack>
)
