import { Components } from "@mui/material"

import { LinkBehavior } from "./LinkBehavior"

export const MuiButtonBase: Required<Components>["MuiButtonBase"] = {
  defaultProps: {
    disableRipple: true,
    LinkComponent: LinkBehavior,
  },
}
