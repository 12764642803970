import { bind } from "@react-rxjs/core"
import {
  defer,
  distinctUntilChanged,
  fromEvent,
  map,
  merge,
  startWith,
} from "rxjs"

const isOnline$ = defer(() =>
  fromEvent(window, "online").pipe(map(() => false)),
)

const isOffline$ = defer(() =>
  fromEvent(window, "offline").pipe(map(() => true)),
)

const networkConnectionState$ = defer(() => {
  return merge(isOnline$, isOffline$).pipe(
    map((result) => result),
    startWith(false),
    distinctUntilChanged(),
  )
})

export const [useIsOffline, isOfflineObservable$] = bind(
  networkConnectionState$,
)
