import { Skeleton, TableCell, TableRow, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useNavigate } from "react-router-dom"

import { RootPath as RootPathDesktop } from "@/desktop/paths"
import { RootPath as RootPathMobile } from "@/mobile/paths"
import {
  ChangeAmount,
  ChangePercentage,
  CurrentPriceValue,
  InstrumentLogo,
} from "@/shared/components"
import { Instrument } from "@/shared/services/TradingGateway"
import { useIsDesktop } from "@/shared/utils"

import { ThemeMode } from "../theme/ThemeContext"

export const InstrumentRow = ({
  row,
  testId,
}: {
  row: Instrument
  testId: string
}) => {
  const navigate = useNavigate()
  const isDesktop = useIsDesktop()

  const rootPath = isDesktop ? RootPathDesktop : RootPathMobile
  const nameVariant = isDesktop ? "Text md/Medium" : "Text lg/Medium"
  const priceVariant = isDesktop ? "Text md/Regular" : "Text lg/Regular"

  return (
    <TableRow
      key={row.name}
      data-testid={testId}
      onClick={() => navigate(rootPath.tradeInstrument(row.symbol))}
      sx={{
        "&:hover": {
          backgroundColor: "background.brandPrimary",
          cursor: "pointer",
          "& .MuiTableCell-root": {
            // TODO Dave - overrides design due to contrast ratio
            color: (theme) =>
              theme.palette.mode === ThemeMode.DARK
                ? "text.primaryAlt"
                : "text.primary",
          },
        },
      }}
    >
      <TableCell>
        <InstrumentLogo variant="sm" symbol={row.symbol} data-testid={`logo`} />
      </TableCell>
      <TableCell align="left" data-testid={`${testId}.name`}>
        <Typography variant={nameVariant}>{row.displayName}</Typography>
      </TableCell>
      <TableCell align="right" data-testid={`${testId}.lastPrice`}>
        <Subscribe fallback={<Skeleton width={50} />}>
          <Typography variant={priceVariant}>
            <CurrentPriceValue symbol={row.symbol} />
          </Typography>
        </Subscribe>
      </TableCell>
      <TableCell align="right" data-testid={`${testId}.priceChange`}>
        <Subscribe fallback={<Skeleton width={50} />}>
          <ChangeAmount symbol={row.symbol} />
        </Subscribe>
      </TableCell>
      <TableCell align="right" data-testid={`${testId}.lastDayChange`}>
        <Subscribe fallback={<Skeleton width={50} />}>
          <ChangePercentage symbol={row.symbol} />
        </Subscribe>
      </TableCell>
    </TableRow>
  )
}
