import { QUANTITY_INPUT_MAX_DECIMAL_PLACES } from "./constants"

export const validateInput = (rawInputValue: string) => {
  if (rawInputValue.includes("-")) {
    return "0" // reset to 0 if negative
  }
  const decimalPosition = rawInputValue.indexOf(".")
  return decimalPosition >= 0
    ? rawInputValue.slice(
        0,
        decimalPosition + QUANTITY_INPUT_MAX_DECIMAL_PLACES + 1,
      )
    : rawInputValue
}
