import { PropsWithChildren, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { useIsDesktop, useIsLoginPage } from "@/shared/utils"

import { helloLogin } from "./login/service"
import { ConnectionState, setEmailLogged } from "./login/signal"
import {
  useEmailLogged,
  useEventBusConnectionState,
  useIsEventBusTimeout,
} from "./login/state"

export const Authenticated = ({ children }: PropsWithChildren) => {
  const eventBusConnectionState = useEventBusConnectionState()

  const navigate = useNavigate()
  const isLoginPage = useIsLoginPage()
  const isDesktop = useIsDesktop()
  const emailLogged = useEmailLogged()
  const emailFromStorage = localStorage.getItem("email")
  const isEventBusTimeout = useIsEventBusTimeout()

  useEffect(() => {
    if (import.meta.env.VITE_MOCKS_BYPASS_LOGIN) {
      return
    }

    if (eventBusConnectionState === ConnectionState.DISCONNECTED) {
      navigate(isDesktop ? "/desktop/login" : "/mobile/login")
      return
    }

    if (
      eventBusConnectionState === ConnectionState.CONNECTED &&
      emailLogged === null
    ) {
      if (emailFromStorage !== null && !isLoginPage) {
        helloLogin(emailFromStorage)
      } else {
        navigate(isDesktop ? "/desktop/login" : "/mobile/login")
      }
    }

    if (isEventBusTimeout) {
      setEmailLogged(null)
    }
  }, [
    navigate,
    emailLogged,
    emailFromStorage,
    eventBusConnectionState,
    isEventBusTimeout,
    isDesktop,
    isLoginPage,
  ])

  return children
}
