import { bind } from "@react-rxjs/core"
import { createSignal, mergeWithKey } from "@react-rxjs/utils"
import { scan, startWith } from "rxjs"

export type DialogParams = {
  isOpen: boolean
  title: string
  description?: string
  onCancel?: () => void
  onConfirm?: () => void
  cancelLabel?: string
  confirmLabel?: string
}

const DEFAULT_CONFIRM_DIALOG: DialogParams = {
  isOpen: false,
  title: "",
}

const [openConfirmDialog$, openConfirmDialog] =
  createSignal<Partial<DialogParams>>()
const [closeConfirmDialog$, closeConfirmDialog] = createSignal()

const confirm$ = mergeWithKey({
  openConfirmDialog$,
  closeConfirmDialog$,
}).pipe(
  scan((acc, current) => {
    switch (current.type) {
      case "closeConfirmDialog$":
        return {
          ...acc,
          isOpen: false,
        }
      case "openConfirmDialog$":
        return {
          ...acc,
          ...current.payload,
          isOpen: true,
          onCancel: () => {
            if (current.payload?.onCancel) current.payload?.onCancel()
            closeConfirmDialog()
          },
          onConfirm: () => {
            if (current.payload?.onConfirm) current.payload?.onConfirm()
            closeConfirmDialog()
          },
        }
      default:
        return acc
    }
  }, DEFAULT_CONFIRM_DIALOG),
  startWith(DEFAULT_CONFIRM_DIALOG),
)

export { closeConfirmDialog, openConfirmDialog }

export const [useDialog] = bind<DialogParams>(confirm$)
